//@ts-nocheck
import React from "react";
import SingleClientController, { Props } from "./SingleClientController.web";
import {
  Typography,
  Button,
  Grid,
  Box,
  withStyles,
  createStyles,
  TextField,
  InputAdornment,
  Hidden,
  Checkbox,
  MenuItem,
  FormControlLabel,
  Collapse,
  IconButton,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { editProfileImage, shareIcon, eyeIcon, deleteIcon } from "../assets";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import PhaseTwoField from "../../../../components/src/MediumBlueField.web";
import AddNewClientDialog from "./AddNewClientDialog.web";
import { PhaseTwoSwitch } from "../../../../components/src/ModifiedComponents.web";
import { Formik, Form, FieldArray, FastField } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SingleClientImageUpload from "./SingleClientImageUpload.web";
import SendSurveyDialog from "./SendSurveyDialog.web";
import ArchivedClientDialog from "./ArchivedClientDialog.web";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddIcon from "@material-ui/icons/Add";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { largeChartOptions } from "../chartOptions";
import { Pie, Line } from "react-chartjs-2";
import moment from "moment";
import AddPrioritiesDialog from "./AddPrioritiesDialog.web";
import DeleteDialog from "./DeleteDialog.web";
import withPermissions from "../../../../components/src/withPermissions.web";
import TernaryCheck from "../TernaryCheck.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const pieChartData = {
  // labels: ["Recurring Payment", "One Time Payment"],
  datasets: [],
};

const labels = [
  "Aug 2020",
  "Sep 2020",
  "Oct 2020",
  "Nov 2020",
  "Dec 2020",
  "Jan 2021",
];

export const lineChartData = {
  labels,
  datasets: [
    {
      label: "",
      data: [83, 135, 117, 195, 117, 140, 165],
      borderColor: "#9CBE6A",
      backgroundColor: "rgba(54, 162, 235, 1)",
      borderWidth: 2,
      pointStyle: "circle",
      pointRadius: 3,
      pointHoverRadius: 4,
    },
  ],
};

export const lineChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    x: {
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        font: {
          size: 16,
        },
      },
      grid: {
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        color: function(context) {
          if (context.tick.value > 0) {
            return "red";
          } else if (context.tick.value < 0) {
            return "green";
          }
          return "#000000";
        },
      },
    },
    y: {
      ticks: {
        color: "rgba(72, 100, 132, 1)",
        stepSize: 20,
        font: {
          size: 16,
        },
      },
      grid: {
        display: false,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        color: function(context) {
          if (context.tick.value > 0) {
            return "red";
          } else if (context.tick.value < 0) {
            return "green";
          }
          return "#000000";
        },
      },
    },
  },
};

const StyledTableCell = withStyles((theme) => ({
  root: {
    width: (props) => (props.width ? props.width : "auto"),
    position: "relative",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
    "&:first-of-type": {
      borderTopLeftRadius: "0.75rem",
      borderBottomLeftRadius: "0.75rem",
    },
    "&:last-of-type": {
      borderTopRightRadius: "0.75rem",
      borderBottomRightRadius: "0.75rem",
    },
  },
  head: {
    color: theme.palette.text.blue2,
    fontSize: "1.2rem",
    fontWeight: "bold",
    fontFamily: "Nunito Sans",
    border: "none",
    backgroundColor: "#F3F3F3",
    padding: "1.2rem 0rem",
  },
  body: {
    fontSize: "1rem",
    color: theme.palette.text.secondary,
    border: "none",
    fontFamily: "Nunito Sans",
    backgroundColor: "#fff",
    padding: "1.2rem 0rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
}))(TableCell);

const clientStatusOptions = ["", "Declined", "Archived"];

const revenueTimePeriodOptions = [
  "Last 12 Months",
  "Last 9 Months",
  "Last 6 Months",
  "Last 3 Months",
];

export class SingleClient extends SingleClientController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    const clientId = await StorageProvider.get("clientId");
    StorageProvider.set("surveyId", "");
    StorageProvider.set("surveyType", "");
    StorageProvider.set("package_management_id", "");

    this.setState(
      {
        token: token,
        accountId: accountId,
        clientId: clientId,
      },
      () => {
        this.getSingleClient();
        this.getSurveyListByClientId();
      }
    );
  }
  goToSelectedClient = (id) => {
    StorageProvider.set("clientId", id);
    window.location.reload();
  };

  removeServerImage = () => {
    this.setState({
      defaultLogoImageFromServer: null,
    });
  };

  openImageUploadDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {
          singleClient: this.state.singleClient,
          showToast: this.props.showToast,
        },
        width: "60%",
        renderedComponent: SingleClientImageUpload,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.uploadPackageImage({ file: data });
        }
      });
  };
  handleStatusChange = (e) => {
    if (e.target.value === "Archived") {
      this.openArchivedDialog(e.target.value);
    } else {
      this.setState({
        clientStatus: e.target.value,
      });
    }
  };

  openArchivedDialog = (value) => {
    this.props
      .openDialogBox({
        dataToPass: {
          value: value,
        },
        width: "45%",
        renderedComponent: ArchivedClientDialog,
        withCustomDialog: false,
      })
      .then(async (dataFromDialog: any) => {
        if (dataFromDialog) {
          this.setState({
            clientStatus: value,
          });
        }
      });
  };

  openSendSurveyDialog = (data) => {
    this.props
      .openDialogBox({
        dataToPass: {},
        width: "45%",
        renderedComponent: SendSurveyDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data) {
          this.sendCFSSurvey();
        }
      });
  };

  openAddPrioritesDialog = (outerData) => {
    this.props
      .openDialogBox({
        dataToPass: {
          // selectedClientsList: outerData.selectedClientsList,
        },
        width: "45%",
        renderedComponent: AddPrioritiesDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data.priority) {
          this.addPriority(outerData.clientId, data.priority);
        }
      });
  };

  openDeleteDialog = (outerData) => {
    this.props
      .openDialogBox({
        dataToPass: {
          dialogHeading: "Delete",
          description: "Are you sure you want to delete this priority item?",
        },
        width: "45%",
        renderedComponent: DeleteDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (!!data) {
          this.deletePriority(outerData.clientId, outerData.priorityId);
        }
      });
  };
  handleEditClient = (data) => {
    if (data?.id) {
      this.props.showToast({
        type: "success",
        message: "Client updated successfully",
      });
      this.getSingleClient();
    }
  };
  openEditClientDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          showToast: this.props.showToast.bind(this),
          clientInfo: this.state.singleClient,
          title: "Edit Client",
          onSubmit: this.handleEditClient.bind(this),
          authToken: this.state.token,
        },
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        width: "45%",
        renderedComponent: AddNewClientDialog,
        withCustomDialog: false,
      })
      .then((data) => {
        if (data?.id) {
          this.props.showToast({
            type: "success",
            message: "Client updated successfully",
          });
          this.getSingleClient();
        }
      });
  };
  handleAutoReminderChange = (event) => {
    this.setState(
      {
        isAutoReminder: event.target.checked,
      },
      () => {
        this.setupAutoReminder();
      }
    );
  };
  toggleDetail = () => {
    this.setState((prevState) => ({
      expandProfile: !prevState.expandProfile,
    }));
  };
  renderRevenue = (classes, theme) => {
    return (
      <>
        <Grid
          item
          xs={11}
          className={[classes.gridSpacing, classes.sectionHeadings]}
        >
          <Typography
            variant="h2"
            style={{
              color: theme.palette.text.blue2,
            }}
          >
            Revenue Information
          </Typography>
        </Grid>
        <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard]}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography
                className={classes.cardHeading}
                style={{
                  marginBottom: "2rem",
                  color: theme.palette.text.blue2,
                }}
              >
                Revenue Breakdown
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              className={classes.revenueBreakdownSection}
            >
              <Grid item xs={6} container alignItems="center">
                <Grid item xs={6}>
                  <Typography className={classes.primaryPointsKeys}>
                    Total Revenue
                  </Typography>
                  <Typography className={classes.secondaryPointsKeys}>
                    Monthly Revenue
                  </Typography>
                  <Typography className={classes.secondaryPointsKeys}>
                    One Time Payments (2)
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.primaryPointsValues}>
                    $ 0
                  </Typography>
                  <Typography className={classes.secondaryPointsValues}>
                    $ 0/m
                  </Typography>
                  <Typography className={classes.secondaryPointsValues}>
                    $ 0
                  </Typography>
                </Grid>
              </Grid>
              <Box className={classes.verticalDevider} />
              {/* <Divider orientation="vertical" flexItem /> */}
              <Grid
                item
                xs={6}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box
                    style={{
                      height: "10rem",
                      width: "18rem",
                      margin: "auto",
                      marginBottom: "2.5rem",
                    }}
                  >
                    <Pie
                      data={pieChartData}
                      options={{
                        rotation: 180,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container>
              <Grid
                item
                xs={12}
                style={{
                  padding: "0px 24px",
                }}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h2"
                  style={{
                    color: theme.palette.text.blue2,
                  }}
                >
                  Revenue
                </Typography>
                <PhaseTwoField
                  onChange={this.handleMonthChange}
                  select
                  value={this.state.revenueTimePeriod}
                  style={{
                    width: "12%",
                    marginRight: "2rem",
                  }}
                >
                  {revenueTimePeriodOptions.map((opt) => {
                    return (
                      <MenuItem key={opt} value={opt}>
                        {opt}
                      </MenuItem>
                    );
                  })}
                </PhaseTwoField>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  padding: "2.25rem 15rem 2.25rem 3rem",
                }}
              >
                <Box
                  style={{
                    height: "25rem",
                  }}
                >
                  <Line
                    data={{
                      labels: [],
                      datasets: [],
                    }}
                    options={largeChartOptions}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 8px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="2%" />
                      <StyledTableCell width="40%">
                        Package Name
                      </StyledTableCell>
                      <StyledTableCell width="32%" align="center">
                        Date of Enrollment
                      </StyledTableCell>
                      <StyledTableCell
                        width="26%"
                        align="right"
                        style={{
                          paddingRight: "3rem",
                        }}
                      >
                        Monthly Revenue by Package
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} className={classes.clientListCard}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 8px",
                  }}
                >
                  <TableBody>
                    {this.state.enrolledPackages?.length > 0 ? (
                      this.state.enrolledPackages?.map(
                        this.renderEnrolledPackageRow.bind(this, classes)
                      )
                    ) : (
                      <TableRow className={classes.clientRow}>
                        <StyledTableCell
                          colSpan={4}
                          width="100%"
                          align="center"
                        >
                          No package enrolled
                        </StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  onDragEnd = (values, result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const todoList = [...values.prioritiesList];
    todoList.splice(source.index, 1);
    todoList.splice(destination.index, 0, values.prioritiesList[source.index]);

    const listWithDragIndex = todoList.map((item, index) => {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          rank: index,
        },
      };
    });

    this.dragPriority(+this.state.singleClient.id, listWithDragIndex);
  };
  renderNewPriori = () => {
    return (
      <Grid item xs={3} container justifyContent="flex-end">
        <Button
          variant="text"
          startIcon={<AddIcon />}
          size="small"
          color="primary"
          disableFocusRipple
          disableRipple
          disableTouchRipple
          id="openAddPrioritesDialog"
          style={{
            fontSize: "1rem",
            height: "auto",
            paddingRight: "1rem",
          }}
          onClick={this.openAddPrioritesDialog.bind(this, {
            clientId: +this.state.singleClient.id,
          })}
        >
          Add New Item
        </Button>
      </Grid>
    );
  };
  renderNoprty = (values, classes) => {
    return values.prioritiesList && values.prioritiesList.length <= 0 ? (
      <Grid item xs={12} container alignItems="center" justifyContent="center">
        <Typography align="center" color="textSecondary">
          No priority available
        </Typography>
      </Grid>
    ) : null;
  };
  renderLeftDroppable = (classes, theme, handleChange) => {
    return (
      <Droppable droppableId="prioritiesList-left">
        {(provided) => {
          return (
            <Grid
              item
              xs={6}
              className={[classes.gridSpacing]}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <Grid container>
                <FieldArray name={`prioritiesList`}>
                  {(fieldArrayProps) => {
                    const { form } = fieldArrayProps;
                    const { values } = form;
                    return values.prioritiesList &&
                      values.prioritiesList.length > 0
                      ? values.prioritiesList
                          .filter((item, index) => index % 2 === 0)
                          .map((todo, index) => {
                            return (
                              <Draggable
                                draggableId={todo.id}
                                index={todo.attributes.rank}
                                key={todo.id}
                              >
                                {(provided) => (
                                  <>
                                    <Grid
                                      item
                                      xs={6}
                                      className={[classes.todoItemGrid]}
                                      container
                                      alignItems="center"
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >
                                      <DragIndicatorIcon
                                        style={{
                                          color: theme.palette.text.secondary,
                                        }}
                                      />
                                      <FastField>
                                        {({ field, form, meta }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                color="primary"
                                                name={`prioritiesList[${todo.attributes.rank}].attributes.status`}
                                                checked={
                                                  values.prioritiesList[
                                                    todo.attributes.rank
                                                  ].attributes.status
                                                }
                                                onChange={this.updatePriorityChange.bind(
                                                  this,
                                                  todo,
                                                  handleChange
                                                )}
                                              />
                                            }
                                            classes={{
                                              label: values.prioritiesList[
                                                todo.attributes.rank
                                              ].attributes.status
                                                ? classes.checkedTodoCheckboxLabel
                                                : classes.todoCheckboxLabel,
                                            }}
                                            style={{
                                              alignItems: "center",
                                              margin: 0,
                                              width: "85%",
                                            }}
                                            label={todo.attributes.name}
                                          />
                                        )}
                                      </FastField>
                                      <img
                                        src={deleteIcon}
                                        className={classes.todoDeleteIcon}
                                        id="openDeleteDialog"
                                        onClick={this.openDeleteDialog.bind(
                                          this,
                                          {
                                            clientId: +this.state.singleClient
                                              .id,
                                            priorityId: +todo.id,
                                          }
                                        )}
                                      />
                                    </Grid>
                                  </>
                                )}
                              </Draggable>
                            );
                          })
                      : null;
                  }}
                </FieldArray>
                {provided.placeholder}
              </Grid>
            </Grid>
          );
        }}
      </Droppable>
    );
  };
  renderPriority = (classes, theme, values, handleChange) => {
    return (
      <>
        <Grid
          item
          xs={9}
          className={[classes.gridSpacing, classes.sectionHeadings]}
        >
          <Typography
            variant="h2"
            style={{
              color: theme.palette.text.blue2,
            }}
          >
            Priorities
          </Typography>
        </Grid>

        {this.renderNewPriori()}

        <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard]}>
          <Grid container className={classes.clientListCard}>
            {values.prioritiesList && values.prioritiesList.length > 0 ? (
              <DragDropContext onDragEnd={this.onDragEnd.bind(this, values)}>
                {this.renderLeftDroppable(classes, theme, handleChange)}
                <Droppable droppableId="prioritiesList-right">
                  {(provided) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        className={[classes.gridSpacing]}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <Grid container>
                          <FieldArray name={`prioritiesList`}>
                            {(fieldArrayProps) => {
                              const { form } = fieldArrayProps;
                              const { values } = form;
                              return values.prioritiesList &&
                                values.prioritiesList.length > 0
                                ? values.prioritiesList
                                    .filter((item, index) => index % 2 !== 0)
                                    .map((todo, index) => {
                                      return (
                                        <Draggable
                                          draggableId={todo.id}
                                          index={todo.attributes.rank}
                                          key={todo.id}
                                        >
                                          {(provided) => (
                                            <>
                                              <Grid
                                                item
                                                xs={6}
                                                className={[
                                                  classes.todoItemGrid,
                                                ]}
                                                container
                                                alignItems="center"
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                              >
                                                <DragIndicatorIcon
                                                  style={{
                                                    color:
                                                      theme.palette.text
                                                        .secondary,
                                                  }}
                                                />
                                                <FastField>
                                                  {({ field, form, meta }) => (
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          color="primary"
                                                          name={`prioritiesList[${todo.attributes.rank}].attributes.status`}
                                                          checked={
                                                            values
                                                              .prioritiesList[
                                                              todo.attributes
                                                                .rank
                                                            ].attributes.status
                                                          }
                                                          onChange={this.updatePriorityChange.bind(
                                                            this,
                                                            todo,
                                                            handleChange
                                                          )}
                                                        />
                                                      }
                                                      classes={{
                                                        label: values
                                                          .prioritiesList[
                                                          todo.attributes.rank
                                                        ].attributes.status
                                                          ? classes.checkedTodoCheckboxLabel
                                                          : classes.todoCheckboxLabel,
                                                      }}
                                                      style={{
                                                        alignItems: "center",
                                                        margin: 0,
                                                        width: "85%",
                                                      }}
                                                      label={
                                                        todo.attributes.name
                                                      }
                                                    />
                                                  )}
                                                </FastField>
                                                <img
                                                  src={deleteIcon}
                                                  className={
                                                    classes.todoDeleteIcon
                                                  }
                                                  onClick={this.openDeleteDialog.bind(
                                                    this,
                                                    {
                                                      clientId: +this.state
                                                        .singleClient.id,
                                                      priorityId: +todo.id,
                                                    }
                                                  )}
                                                />
                                              </Grid>
                                            </>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                : null;
                            }}
                          </FieldArray>
                          {provided.placeholder}
                        </Grid>
                      </Grid>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            ) : null}
            {this.renderNoprty(values, classes)}
          </Grid>
        </Grid>
      </>
    );
  };
  getAvtarName = () => {
    let avtarName = null;
    if (
      this.state.singleClient?.attributes?.first_name &&
      this.state.singleClient?.attributes?.last_name
    ) {
      avtarName = `${this.state.singleClient?.attributes?.first_name.charAt(
        0
      )}${this.state.singleClient?.attributes?.last_name.charAt(0)}`;
    } else if (this.state.singleClient?.attributes?.first_name) {
      avtarName = `${this.state.singleClient?.attributes?.first_name.charAt(
        0
      )}`;
    }
    return avtarName;
  };
  renderProposal = (classes, theme) => {
    return (
      <>
        <Grid
          item
          xs={11}
          className={[classes.gridSpacing, classes.sectionHeadings]}
        >
          <Typography
            variant="h2"
            style={{
              color: theme.palette.text.blue2,
            }}
          >
            Proposals
          </Typography>
        </Grid>

        <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard]}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 8px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="2%" />
                      <StyledTableCell width="40%">
                        Name of Proposal
                      </StyledTableCell>
                      <StyledTableCell width="31%" align="center">
                        Date Created | Time
                      </StyledTableCell>
                      <StyledTableCell width="12%">Date Shared</StyledTableCell>
                      <StyledTableCell width="15%" align="center">
                        Actions
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} className={[classes.clientListCard]}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 8px",
                  }}
                >
                  <TableBody>
                    {this.state.proposals?.length > 0 ? (
                      this.state.proposals.map((item, index) => {
                        return (
                          <TableRow
                            key={item?.id}
                            className={classes.clientRow}
                          >
                            <StyledTableCell width="2%">
                              <Box
                                className={classes.clientListColorBar}
                                style={{
                                  backgroundColor: theme.palette.primary.main,
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontWeight: "bold",
                              }}
                              width="40%"
                            >
                              {item.name}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              style={{
                                fontWeight: 700,
                              }}
                              width="31%"
                            >
                              {moment(item?.created_at).format(
                                "DD-MM-YY | hh:mm"
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                fontWeight: 700,
                              }}
                              width="15%"
                            >
                              {moment(item?.created_at).format("DD-MM-YY")}
                            </StyledTableCell>
                            <StyledTableCell align="center" width="15%">
                              <img
                                src={shareIcon}
                                alt="shareIcon"
                                style={{
                                  height: "1.7rem",
                                  width: "1.7rem",
                                  marginRight: "1rem",
                                }}
                              />
                              <img
                                src={eyeIcon}
                                onClick={this.gotoRViewoadmap.bind(this, item)}
                                alt="eyeIcon"
                                style={{
                                  height: "1.7rem",
                                  width: "1.7rem",
                                  cursor: "pointer",
                                }}
                              />
                            </StyledTableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow className={classes.clientRow}>
                        <StyledTableCell
                          colSpan={5}
                          width="100%"
                          align="center"
                        >
                          No proposal available
                        </StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  renderDocuments = (classes, theme, permissions) => {
    return (
      <>
        <Grid
          item
          xs={11}
          className={[classes.gridSpacing, classes.sectionHeadings]}
        >
          <Typography
            variant="h2"
            style={{
              color: theme.palette.text.blue2,
            }}
          >
            Documents
          </Typography>
        </Grid>

        <Grid item xs={12} className={[classes.gridSpacing, classes.whiteCard]}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 8px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width="2%" />
                      <StyledTableCell width="56%">
                        Document Name
                      </StyledTableCell>
                      <StyledTableCell width="27%">Status</StyledTableCell>
                      <StyledTableCell width="15%">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={12} className={[classes.clientListCard]}>
              <TableContainer>
                <Table
                  aria-label="simple table"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0px 8px",
                  }}
                >
                  <TableBody>
                    {this.state.document_templates.length > 0
                      ? this.state.document_templates?.map((item, index) => {
                          const isStatus = !item.status;
                          return this.renderDocumentsRow(
                            index,
                            classes,
                            isStatus,
                            item,
                            theme,
                            permissions
                          );
                        })
                      : this.renderNoDocument(classes)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  renderDocumentsRow = (index, classes, isStatus, item, theme, permissions) => {
    return (
      <TableRow key={index} className={classes.clientRow}>
        <StyledTableCell width="2%">
          <Box
            className={classes.clientListColorBar}
            style={{
              backgroundColor: isStatus ? "#ed1867" : "#54A4A6",
            }}
          />
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontWeight: "bold",
          }}
          width="56%"
        >
          {item?.title}
        </StyledTableCell>
        <StyledTableCell
          style={{
            color: isStatus ? "#ed1867" : "#54A4A6",
            fontWeight: "bold",
          }}
          width="27%"
        >
          {isStatus ? "Signature Pending" : "Completed"}
        </StyledTableCell>
        <StyledTableCell
          style={{
            color: isStatus
              ? theme.palette.primary.main
              : theme.palette.text.secondary,
            fontWeight: "bold",
          }}
          width="15%"
        >
          {isStatus ? (
            <Button
              variant="text"
              className={classes.linkText}
              onClick={this.sendReminderEmail.bind(
                this,
                item?.survey_id,
                item?.onboarding_survey?.package_management_id,
                item?.onboarding_survey?.client_information_id
              )}
              disableRipple
              disableFocusRipple
              disableTouchRipple
              style={{
                height: "auto",
                textTransform: "none",
                color: theme.palette.text.secondary,
                fontWeight: "bold",
              }}
            >
              Send Reminder Email
            </Button>
          ) : (
            <TernaryCheck
              condition={
                !permissions.includes(
                  "View Documents (post signature)-eSignatures"
                )
              }
              trueComponent={
                <Button
                  variant="text"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  disabled
                  className={classes.disabledButton}
                >
                  View Document
                </Button>
              }
              falseComponent={
                <Button
                  variant="text"
                  className={classes.linkText}
                  onClick={this.viewSignedDocument.bind(this, item)}
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  style={{
                    height: "auto",
                    textTransform: "none",
                    color: theme.palette.text.secondary,
                    fontWeight: "bold",
                  }}
                >
                  View Document
                </Button>
              }
            />
          )}
        </StyledTableCell>
      </TableRow>
    );
  };
  renderNoDocument = (classes) => {
    return (
      <TableRow className={classes.clientRow}>
        <StyledTableCell colSpan={4} width="100%" align="center">
          No document available
        </StyledTableCell>
      </TableRow>
    );
  };

  renderExpandBox = (theme) => {
    return (
      <Box
        style={{
          position: "absolute",
          bottom: "-10px",
          right: "50%",
          borderRadius: "4px",
          backgroundColor: theme.palette.primary.main,
          width: "1.5rem",
          height: "1.5rem",
          cursor: "pointer",
        }}
        id="expandicon"
        onClick={this.toggleDetail.bind(this)}
      >
        <TernaryCheck
          condition={this.state.expandProfile}
          trueComponent={
            <ExpandLessIcon
              style={{
                color: "#fff",
              }}
            />
          }
          falseComponent={
            <ExpandMoreIcon
              style={{
                color: "#fff",
              }}
            />
          }
        />
      </Box>
    );
  };
  renderProfilePicture = (classes) => {
    return (
      <Grid item xs={2} className={classes.profilePictureContainer}>
        <Box
          style={{
            width: "11.875rem",
            height: "11.875rem",
            borderRadius: "0.75rem",
            backgroundImage: `url(${this.state.singleClient?.attributes?.avatar})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            position: "relative",
          }}
          className={classes.nameInitialsContainer}
        >
          {!this.state.singleClient?.attributes?.avatar ? (
            <Typography className={classes.nameIntialsLogo}>
              {this.getAvtarName()}
            </Typography>
          ) : null}
          <Box
            className={classes.profileImageEdit}
            onClick={this.openImageUploadDialog}
          >
            <img
              src={editProfileImage}
              alt="edit-icon"
              className={classes.editImageIcon}
            />
          </Box>
        </Box>
      </Grid>
    );
  };
  renderPeronalData = (classes, theme) => {
    return (
      <Grid
        item
        xs={5}
        style={{
          paddingLeft: theme.spacing(2),
        }}
        container
      >
        <Grid item xs={12} container alignItems="flex-start">
          <Typography
            display="inline"
            color="textSecondary"
            className={classes.clientName}
            style={{
              fontSize: "1.3rem",
              fontWeight: "bold",
              color: theme.palette.text.blue2,
              maxWidth: "31.25rem",
              display: "inline-block",
            }}
            noWrap
          >
            {this.state.singleClient?.attributes?.first_name
              ? `${this.state.singleClient?.attributes?.first_name} ${this.state.singleClient?.attributes?.last_name}`
              : "NA"}
          </Typography>

          <Box className={classes.individualTypography}>
            {this.state.singleClient?.attributes?.client_type || "NA"}
          </Box>
          <Typography
            display="inline"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
              color:
                this.state.singleClient?.attributes?.profile_status.includes(
                  "Pending"
                ) ||
                this.state.singleClient?.attributes?.profile_status.includes(
                  "Declined"
                )
                  ? theme.palette.error.main
                  : theme.palette.text.blue2,
              paddingLeft: theme.spacing(1),
            }}
          >
            ({this.state.singleClient?.attributes?.profile_status})
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ maxWidth: "fit-content" }}>
          <Typography
            color="textSecondary"
            className={classes.linkText}
            id="openEditClientDialog"
            style={{
              fontWeight: 600,
              display: "inline-block",
            }}
            onClick={this.openEditClientDialog}
          >
            Edit Profile
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <PhaseTwoField
              onChange={this.handleStatusChange.bind(this)}
              id="clientStatus"
              select
              className="clientStatus"
              value={this.state.clientStatus}
              style={{
                width: "25%",
              }}
            >
              {clientStatusOptions.map((opt) => {
                return (
                  <MenuItem
                    key={opt}
                    value={opt}
                    disabled={
                      opt ===
                      this.state.singleClient?.attributes?.profile_status
                    }
                    style={{
                      display: opt === "" ? "none" : "block",
                      borderTop: opt === "Declined" ? "none" : undefined,
                      textAlign: "center",
                    }}
                  >
                    {opt}
                  </MenuItem>
                );
              })}
            </PhaseTwoField>
            <Button
              className={classes.saveButton}
              disabled={
                !this.state.clientStatus &&
                this.state.clientStatus !=
                  this.state.singleClient?.attributes?.profile_status
              }
              color="secondary"
              variant="contained"
              onClick={this.updateStatus}
            >
              Save
            </Button>
          </Grid>
          <Typography
            color="textSecondary"
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: theme.palette.text.secondary,
              marginBottom: theme.spacing(1),
              marginTop: theme.spacing(1),
              maxWidth: "31.25rem",
            }}
            noWrap
          >
            {this.state.singleClient?.attributes?.primary_phone || "NA"}
          </Typography>
          <Typography
            color="textSecondary"
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              color: theme.palette.text.secondary,
              marginBottom: theme.spacing(1),
              marginTop: theme.spacing(1),
              maxWidth: "31.25rem",
            }}
            noWrap
          >
            {this.state.singleClient?.attributes?.primary_email || "NA"}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  isOptionDisable = (option) => {
    return option?.attributes?.noData;
  };
  renderProfileDetail = (classes, theme) => {
    return (
      <Grid item xs={10} container className={classes.profileDetailsContainer}>
        {this.renderPeronalData(classes, theme)}
        <Grid
          item
          xs={4}
          style={{
            paddingLeft: theme.spacing(2),
          }}
          container
        >
          <Grid
            item
            xs={12}
            style={{ width: "fit-content", maxWidth: "fit-content" }}
            container
            direction="column"
          >
            <CustomTypography
              color="textSecondary"
              fontSize="1rem"
              component="span"
              fontWeight={600}
            >
              Linked Clients:
            </CustomTypography>
            {[
              ...new Map(
                this.state.singleClient?.attributes?.linked_clients.map(
                  (item) => [item["id"], item]
                )
              ).values(),
            ]?.map(this.renderLinkedClient.bind(this))}

            <CustomTypography
              color="textSecondary"
              component="span"
              fontSize="1.1rem"
              fontWeight={600}
              className={classes.linkText}
              onClick={this.toggleSearchField}
              style={{
                display: "inline-block",
              }}
            >
              + Link Client Records
            </CustomTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              {this.state.isSearchFieldVisible && (
                <Autocomplete
                  id="Search .."
                  loading={this.state.seatchLoading}
                  loadingText="Loading..."
                  noOptionsText="No Options found"
                  style={{ width: "75%" }}
                  getOptionLabel={this.renderClientSearchOption.bind(this)}
                  options={this.state.clientoptions || []}
                  freeSolo
                  value={this.state.selectedClientForlink}
                  onChange={this.setSelectedClient.bind(this)}
                  getOptionDisabled={this.isOptionDisable.bind(this)}
                  onInputChange={(event, newInputValue) => {
                    this.setState(
                      {
                        linkClientField: newInputValue,
                      },
                      () => {
                        this.debounce(
                          this.searchClient(this.state.linkClientField),
                          1000
                        );
                      }
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search.."
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            onClick={
                              this.state.selectedClientForlink &&
                              this.linkClient
                            }
                            position="end"
                          >
                            <IconButton
                              disabled={!this.state.selectedClientForlink}
                            >
                              <AddCircleOutlineIcon
                                color={
                                  this.state.selectedClientForlink
                                    ? "primary"
                                    : "default"
                                }
                                style={
                                  this.state.selectedClientForlink && {
                                    cursor: "pointer",
                                  }
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                        classes: {
                          root: classes.autocompleteRoot,
                        },
                      }}
                    />
                  )}
                  renderOption={this.renderOption.bind(this)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          container
          justifyContent="flex-end"
          alignItems="space-between"
        >
          <Grid
            item
            md={10}
            container
            direction="column"
            justifyContent="space-between"
            className={classes.pendingItemsCard}
          >
            <Typography
              align="center"
              className={classes.pendingItemsCard__Number}
            >
              -
            </Typography>
            <Typography
              align="center"
              color="textSecondary"
              className={classes.pendingItemsCard__Text}
            >
              Items Pending
            </Typography>
          </Grid>
          <Grid item md={10} container justifyContent="center">
            <FormControlLabel
              classes={{
                label: classes.switchLabel,
                root: classes.formLabelRoot,
              }}
              value="start"
              control={
                <PhaseTwoSwitch
                  checked={this.state.isAutoReminder}
                  onChange={this.handleAutoReminderChange}
                  color="primary"
                />
              }
              label="Auto Reminder"
              labelPlacement="start"
            />
            <Typography
              align="right"
              style={{
                fontSize: "1.3rem",
                color: theme.palette.text.secondary,
              }}
            >
              Assigned to:{" "}
              <Typography
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                }}
                display="inline"
              >
                -
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  renderLinkedClient = (client, classes) => {
    return (
      <CustomTypography
        key={client.id}
        color="primary"
        fontSize="1rem"
        fontWeight={600}
        style={{ cursor: "pointer", textDecoration: "underline" }}
        className={classes.linkText}
        onClick={this.goToSelectedClient.bind(this, client.id)}
      >
        {client.name}
      </CustomTypography>
    );
  };
  renderClientSearchOption = (option) => {
    return typeof option === "string"
      ? "option"
      : option?.attributes?.first_name + " " + option?.attributes?.last_name;
  };
  renderOption = (option) => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            {option.attributes.first_name} {option.attributes.last_name}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  setSelectedClient = (event: any, newValue: any | null) => {
    if (!newValue?.attributes?.noData) {
      this.setState({
        selectedClientForlink: newValue,
        clientoptions: newValue
          ? [newValue, ...this.state.clientoptions]
          : this.state.clientoptions,
      });
    }
  };
  renderEnrolledPackageRow = (classes, item) => {
    return (
      <TableRow key={item?.id} className={classes.clientRow}>
        <StyledTableCell width="2%">
          <Box
            className={classes.clientListColorBar}
            style={{
              backgroundColor: "#ed1867",
            }}
          />
        </StyledTableCell>
        <StyledTableCell
          style={{
            fontWeight: "bold",
          }}
          width="40%"
        >
          {item.package_name}
        </StyledTableCell>
        <StyledTableCell
          align="center"
          style={{
            fontWeight: 600,
          }}
          width="32%"
        >
          {moment(item.date_of_enrollment, "DD/MM/YYYY").format("DD-MM-YY")}
        </StyledTableCell>
        <StyledTableCell
          align="right"
          style={{
            paddingRight: "3rem",
            fontWeight: 600,
          }}
          width="26%"
        >
          not available
        </StyledTableCell>
      </TableRow>
    );
  };
  toggleSearchField = () => {
    this.setState({
      isSearchFieldVisible: true,
    });
  };
  updatePriorityChange = (todo, handleChange, e) => {
    handleChange(e);
    this.updatePriority(
      e.target.checked,
      +this.state.singleClient.id,
      +todo.id
    );
  };
  gotoRViewoadmap = async (item) => {
    await StorageProvider.set("roadMapId", item.id);
    StorageProvider.set("surveyType", "roadmap");
    await StorageProvider.set("package_management_id", "");
    this.props.history.push("/home/clientsList/details/valueRoadmap", {
      roadMapId: item.id,
      surveyType: "roadmap",
    });
  };
  goToRoadmapForSurvey = async (item) => {
    await StorageProvider.set("surveyId", item.id);
    await StorageProvider.set("roadMapId", null);
    await StorageProvider.set(
      "surveyType",
      item.attributes.about_us.data.attributes.survey_type
    );
    await StorageProvider.set("package_management_id", "");
    this.props.history.push("/home/clientsList/details/valueRoadmap", {
      surveyId: item.id,
      surveyType: item.attributes.about_us.data.attributes.survey_type,
    });
  };
  handleMonthChange = (e) => {
    this.setState({
      revenueTimePeriod: e.target.value,
    });
  }

  render() {
    const { classes, theme, permissions } = this.props;

    return (
      <Formik
        initialValues={{
          prioritiesList: this.state.prioritiesList,
        }}
        enableReinitialize
        onSubmit={(values) => {
          this.updateChecklist(values);
        }}
      >
        {(formikProps) => {
          const { values, handleChange } = formikProps;

          return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                justifyContent="space-between"
                style={{
                  position: "relative",
                }}
              >
                <Hidden smUp>
                  <Grid item xs={12} className={[classes.gridSpacing]}>
                    <TextField
                      variant="outlined"
                      placeholder="Search..."
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              paddingRight: 0,
                            }}
                          >
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Hidden>

                <Grid
                  item
                  xs={12}
                  className={[classes.gridSpacing, classes.whiteCard]}
                  style={{
                    position: "relative",
                  }}
                >
                  {this.renderExpandBox(theme)}
                  <Grid container alignItems="center">
                    {this.renderProfilePicture(classes)}
                    {this.renderProfileDetail(classes, theme)}
                    <CustomGrid
                      width="87%"
                      margin="0 0 0 13%"
                      padding="0 0 0 16px"
                      item
                      xs={10}
                    >
                      <Collapse
                        in={this.state.expandProfile}
                        timeout="auto"
                        unmountOnExit
                        style={{
                          width: "100%",
                        }}
                      >
                        {this.state.singleClient?.attributes?.client_custom_fields?.data.map(
                          (custom_field) => {
                            return (
                              <CustomTypography
                                key={custom_field?.id}
                                color="textSecondary"
                                margin="0 0 0.5rem 0"
                              >
                                {custom_field?.attributes.custom_field?.key}:{" "}
                                {custom_field?.attributes.custom_field?.value ||
                                  custom_field?.attributes?.value}
                              </CustomTypography>
                            );
                          }
                        )}
                      </Collapse>
                    </CustomGrid>
                  </Grid>
                </Grid>

                {this.renderRevenue(classes, theme)}

                {this.renderPriority(classes, theme, values, handleChange)}

                {this.renderProposal(classes, theme)}

                {this.renderDocuments(classes, theme, permissions)}

                <Grid
                  item
                  xs={9}
                  className={[classes.gridSpacing, classes.sectionHeadings]}
                  container
                  alignItems="center"
                >
                  <Typography
                    variant="h2"
                    style={{
                      color: theme.palette.text.blue2,
                    }}
                  >
                    Survey Information
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={[classes.gridSpacing]}
                  style={{
                    paddingRight: "2rem",
                  }}
                  container
                  alignItems="center"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    id="openSendSurveyDialog"
                    fullWidth
                    onClick={this.openSendSurveyDialog.bind(this)}
                    disabled={
                      !permissions.includes(
                        "Send Survey-Client Conversion Survey"
                      )
                    }
                  >
                    + Send Survey
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={[classes.gridSpacing, classes.whiteCard]}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table
                          aria-label="simple table"
                          style={{
                            borderCollapse: "separate",
                            borderSpacing: "0px 8px",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell width="2%" />
                              <StyledTableCell width="32%">
                                Date of Submission
                              </StyledTableCell>
                              <StyledTableCell width="28%" align="center">
                                Type
                              </StyledTableCell>
                              <StyledTableCell width="24%" align="center">
                                Time
                              </StyledTableCell>
                              <StyledTableCell
                                width="14%"
                                style={{
                                  paddingRight: theme.spacing(2),
                                }}
                              >
                                Surveys
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center">
                    <Grid item xs={12} className={[classes.clientListCard]}>
                      <TableContainer>
                        <Table
                          aria-label="simple table"
                          style={{
                            borderCollapse: "separate",
                            borderSpacing: "0px 8px",
                          }}
                        >
                          {this.state.surveyList &&
                          this.state.surveyList.length > 0 ? (
                            this.state.surveyList.map((item, index) => {
                              return (
                                <TableBody key={item?.id}>
                                  <TableRow className={classes.clientRow}>
                                    <StyledTableCell width="2%">
                                      <Box
                                        className={classes.clientListColorBar}
                                        style={{
                                          backgroundColor: "#54A4A6",
                                        }}
                                      />
                                    </StyledTableCell>
                                    <StyledTableCell
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                      width="32%"
                                    >
                                      {moment(
                                        item.attributes.created_at
                                      ).format("DD-MM-YY")}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                      align="center"
                                      width="28%"
                                    >
                                      {item.attributes.type ||
                                        "Client Conversion Survey"}
                                    </StyledTableCell>
                                    <StyledTableCell
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                      align="center"
                                      width="24%"
                                    >
                                      {moment(
                                        item.attributes.created_at
                                      ).format("hh:mm")}
                                    </StyledTableCell>

                                    <StyledTableCell
                                      style={{
                                        paddingRight: theme.spacing(2),
                                        color: theme.palette.primary.main,
                                        fontWeight: "bold",
                                      }}
                                      width="14%"
                                    >
                                      <TernaryCheck
                                        condition={
                                          !permissions.includes(
                                            "Access Survey Responses-Client Conversion Survey"
                                          )
                                        }
                                        trueComponent={
                                          <Button
                                            disableRipple
                                            disableFocusRipple
                                            disableTouchRipple
                                            disabled
                                            type="text"
                                            className={classes.disabledButton}
                                          >
                                            View Submission
                                          </Button>
                                        }
                                        falseComponent={
                                          <Button
                                            type="text"
                                            className={classes.linkText}
                                            onClick={this.goToRoadmapForSurvey.bind(
                                              this,
                                              item
                                            )}
                                            disableRipple
                                            disableFocusRipple
                                            disableTouchRipple
                                            style={{
                                              height: "auto",
                                              textTransform: "none",
                                              color:
                                                theme.palette.text.secondary,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            View Submission
                                          </Button>
                                        }
                                      />
                                    </StyledTableCell>
                                  </TableRow>
                                </TableBody>
                              );
                            })
                          ) : (
                            <TableBody>
                              <TableRow className={classes.clientRow}>
                                <StyledTableCell
                                  colSpan={5}
                                  align="center"
                                  width={"100%"}
                                >
                                  No survey available
                                </StyledTableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const SingleClientWithRouter = withRouter(SingleClient);
const SingleClientAlertBox = withAlertBox(SingleClientWithRouter);
const SingleClientLoader = withLoader(SingleClientAlertBox);
const SingleClientToast = withToast(SingleClientLoader);
const SingleClientWithDialog = withDialog(SingleClientToast);
const SingleClientWithConfirmBox = withConfirmBox(SingleClientWithDialog);
const SingleClientWithPermissions = withPermissions(SingleClientWithConfirmBox);

export default withStyles(
  (theme) =>
    createStyles({
      gridSpacing: {
        margin: theme.spacing(2, 0),
        [theme.breakpoints.down("md")]: {
          margin: theme.spacing(1, 0),
        },
      },
      whiteCard: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        backgroundColor: "#ffffff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(1.5),
        },
      },
      cardHeading: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        letterSpacing: 1,
      },
      clientListCard: {
        maxHeight: "32vh",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "2px",
          height: "100px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F6",
          borderRadius: theme.spacing(1),
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#BBBEC6",
          borderRadius: theme.spacing(1),
        },
      },
      clientListCardWithoutPadding: {
        maxHeight: "45vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "10px",
          height: "100px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F6",
          borderRadius: theme.spacing(1),
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#BBBEC6",
          borderRadius: theme.spacing(1),
        },
      },
      sectionHeadings: {
        paddingLeft: theme.spacing(2),
      },
      clientRowHeading: {
        margin: "0.25rem 0",
        padding: "0.5rem 0",
        borderRadius: "0.75rem",
        backgroundColor: "#f3f3f3",
      },
      clientRow: {
        margin: "0.25rem 0",
        padding: "0.5rem 0",
        backgroundColor: "#fff",
        position: "relative",
        borderRadius: "0.75rem",
        fontFamily: "Nunito Sans",
        boxShadow: "0 4px 18px 0 rgba(0, 0, 0, 0.09)",
      },
      clientListColorBar: {
        position: "absolute",
        left: 0,
        top: "30%",
        width: "4px",
        height: "40%",
      },
      clientRowLeft: {
        margin: theme.spacing(1.5, 0),
        padding: theme.spacing(2.5, 0),
        borderRadius: theme.spacing(2),
        backgroundColor: "#fff",
        boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
        cursor: "pointer",
      },
      individualTypography: {
        display: "inline-block",
        backgroundColor: "#fff",
        border: `1px solid ${theme.palette.text.blue2}`,
        borderRadius: "100vmax",
        marginLeft: theme.spacing(1),
        padding: theme.spacing(0.5, 1),
        fontSize: "0.85rem",
        fontWeight: "bold",
        color: theme.palette.text.blue2,
        [theme.breakpoints.down("md")]: {
          fontSize: "0.9rem",
        },
      },
      clientName: {
        fontSize: "1.3rem",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
      },
      packagesEnrolledByUser: {
        fontSize: "1.1rem",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
      },
      leftSectionContainer: {
        overflowY: "auto",
        maxHeight: "100vh",
        "&::-webkit-scrollbar": { display: "none" },
        scrollbarWidth: "none",
      },
      rightSectionContainer: {
        borderRadius: theme.spacing(2),
        padding: "40px 20px 20px 20px",
      },
      rightSectionInnerContainer: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1.5),
      },
      primaryPointsKeys: {
        color: theme.palette.text.secondary,
        fontSize: "1.2rem",
        marginBottom: "1rem",
        fontWeight: "bold",
        paddingLeft: theme.spacing(1),
      },
      primaryPointsValues: {
        color: theme.palette.primary.main,
        fontSize: "1.2rem",
        marginBottom: "1rem",
        fontWeight: "bold",
      },
      secondaryPointsKeys: {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        marginBottom: "1rem",
        fontWeight: "bold",
        paddingLeft: theme.spacing(3),
      },
      secondaryPointsValues: {
        color: theme.palette.primary.main,
        fontSize: "1rem",
        marginBottom: "1rem",
        fontWeight: "bold",
      },
      todoItemGrid: {
        backgroundColor: "#f8f8f8",
        padding: "0.5rem",
        margin: "8px 1%",
        borderRadius: "1rem",
        flexGrow: 0,
        maxWidth: "98%",
        flexBasis: "98%",
      },
      todoItemSkeletonGrid: {
        backgroundColor: "#f8f8f8",
        padding: "0.5rem",
        margin: "8px 1%",
        borderRadius: "1rem",
      },
      revenueBreakdownSection: {
        padding: "0px 24px",
        position: "relative",
        borderBottom: `1px solid #e0e0e0`,
        marginBottom: "20px",
      },
      verticalDevider: {
        position: "absolute",
        width: "1px",
        height: "100%",
        backgroundColor: "#e0e0e0",
        bottom: 0,
        left: "50%",
      },
      profilePictureContainer: {
        position: "relative",
        flexGrow: 0,
        flexBasis: "13%",
        maxWidth: "13%",
      },
      profileDetailsContainer: {
        flexGrow: 0,
        flexBasis: "87%",
        maxWidth: "87%",
      },
      profileImageEdit: {
        position: "absolute",
        height: "1.875rem",
        width: "1.875rem",
        right: "3%",
        top: "3%",
        cursor: "pointer",
      },
      editImageIcon: {
        height: "1.875rem",
        width: "1.875rem",
      },
      pieChartBlueBox: {
        height: "20px",
        width: "20px",
        borderRadius: "5px",
        backgroundColor: "#8386F1",
        display: "inline-block",
        marginRight: "10px",
        marginTop: "5px",
      },
      pieChartGreenBox: {
        height: "20px",
        width: "20px",
        borderRadius: "5px",
        backgroundColor: "#91CFCC",
        display: "inline-block",
        marginRight: "10px",
        marginTop: "5px",
      },
      pieChartText: {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        marginBottom: "0.625rem",
      },
      sideLink: {
        fontFamily: "Nunito Sans",
        fontSize: "1.1rem",
        color: theme.palette.text.secondary,
        cursor: "pointer",
        textAlign: "left",
        textDecoration: "none",
        fontWeight: "bold",
        borderBottom: "2px solid #f4f4f4",
        padding: "0px 10px",
        "&:first-of-type": {
          paddingLeft: "0px",
        },
      },
      activeSideLink: {
        color: theme.palette.text.blue2,
        borderRadius: "8px",
        textTransform: "none",
        textDecoration: "none",
        cursor: "pointer",
        fontSize: "1.3158rem",
        fontWeight: "bold",
        borderBottom: `3px solid ${theme.palette.text.blue2}`,
        padding: "0px 10px",
        "&:first-of-type": {
          paddingLeft: "0px",
        },
      },
      pendingItemsCard: {
        backgroundColor: "#f4f4f4",
        borderRadius: "0.75rem",
        padding: "1rem",
      },
      pendingItemsCard__Number: {
        color: theme.palette.error.main,
        fontSize: "1.5rem",
        fontWeight: "bold",
      },
      pendingItemsCard__Text: {
        fontSize: "1.2rem",
        fontWeight: "bold",
      },
      switchLabel: {
        fontFamily: "Nunito Sans",
        fontSize: "1.3158rem",
        fontWeight: 700,
        color: theme.palette.text.secondary,
        textAlign: "right",
        marginTop: "0.5rem",
      },
      formLabelRoot: {
        marginRight: 0,
      },
      linkText: {
        textDecoration: "underline",
        cursor: "pointer",
      },
      nameInitialsContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f3f3f3",
        borderRadius: "1rem",
      },
      nameIntialsLogo: {
        fontSize: "5rem",
        color: theme.palette.text.secondary,
        textTransform: "uppercase",
      },
      saveButton: {
        height: "2.2rem",
        borderRadius: "100vmax",
        marginLeft: "0.5rem",
        backgroundColor: theme.palette.text.blue2,
      },
      containerSaveButton: {
        marginTop: "2rem",
        paddingRight: "2rem",
      },
      todoCheckboxLabel: {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        fontWeight: "bold",
      },
      checkedTodoCheckboxLabel: {
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        fontWeight: "bold",
        textDecoration: "line-through",
      },
      todoDeleteIcon: {
        height: "22px",
        cursor: "pointer",
        marginLeft: "auto",
        marginRight: "1rem",
      },
      disabledButton: {
        height: "auto",
        textTransform: "none",
        textDecoration: "none",
        fontWeight: "bold",
        cursor: "none",
      },
      autocompleteRoot: {
        padding: "0 12px!important",
        borderRadius: "50px",
        background: "#fff",
        border: `1px solid ${theme.palette.text.secondary}`,
      },
    }),
  { withTheme: true }
)(SingleClientWithPermissions);
