//@ts-nocheck
import React from "react";
import FirmPricingController, { Props } from "./FirmPricingController.web";
import { Typography, Grid, withStyles, createStyles, Button, IconButton, Tabs, Tab } from "@material-ui/core";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import { blackEditIconPng } from "../assets";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { formatCurrency } from "../CustomPackage/CustomPackageCreationSelectFeaturesPage.web";
import moment from "moment";
import TernaryCheck from "../TernaryCheck.web";
import ManageUsersCommonDialog from "./ManageUsersCommonDialog.web";
import { StyledTableCell, BootstrapTooltip } from "../FirmFinancialsNew.web";

export const PricingDataTable = ({
  classes,
  theme,
  state,
  props,
  customPackages,
  createCustomPackage,
  defaultPackages,
  editDefaultPackage,
  editCustomPackage,
  openDeleteCustomPackageDialog,
  getPackagePrices,
  getPackageModifiedTime,
  isOnTrackToReachGoal,
}) => {
  const businessPackages = defaultPackages.filter((item) => item.attributes.package_type === "Business Package");
  const individualPackages = defaultPackages.filter((item) => item.attributes.package_type === "Individual Package");

  return (
    <TableContainer
      style={{
        width: "100%",
        borderRadius: "16px",
        boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
        border: `1px solid ${theme.palette.text.secondary}`,
      }}
    >
      <Table style={{ width: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell textcolor={"#fff"} color="#f9f9f9" width="25%" className={classes.columnsHeading}>
              Package Name
            </StyledTableCell>
            <StyledTableCell textcolor={"#fff"} color="#f9f9f9" align="center" width="25%" className={classes.columnsHeading}>
              Last Modified
            </StyledTableCell>
            <StyledTableCell textcolor={"#fff"} color="#f9f9f9" align="center" width="25%" className={classes.columnsHeading}>
              Price per package
            </StyledTableCell>
            <StyledTableCell textcolor={"#fff"} color="#f9f9f9" align="center" width="25%" className={classes.columnsHeading}>
              Modify?
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell
              className={classes.columnsHeading}
              style={{
                padding: "0.50rem",
                backgroundColor: "#fff",
                border: "none",
              }}
              colSpan={4}
            ></StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell color="#f9f9f9" className={classes.columnsHeading} colSpan={4}>
              Business Packages
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {businessPackages.length > 0 ? (
            businessPackages.map((businessPackage) => {
              return (
                <TableRow key={businessPackage?.id}>
                  <StyledTableCell className={classes.textForGoals}>{businessPackage?.attributes?.name}</StyledTableCell>
                  <StyledTableCell align="center" className={classes.textForGoals}>
                    {getPackageModifiedTime(businessPackage)}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.textForGoals}>
                    {getPackagePrices(businessPackage)}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.textForGoals}>
                    <TernaryCheck
                      condition={state.activeTab === "Default"}
                      trueComponent={
                        <img
                          src={blackEditIconPng}
                          onClick={editDefaultPackage.bind(this,businessPackage)}
                          className={classes.editIconStyles}
                          alt="blackEditIconPng"
                        />
                      }
                      falseComponent={<NotInterestedIcon />}
                    />
                  </StyledTableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <StyledTableCell colSpan={4} align="center" className={classes.textForGoals}>
                No Packages Available
              </StyledTableCell>
            </TableRow>
          )}
          <TableRow>
            <StyledTableCell color="#f9f9f9" className={[classes.columnsHeading, classes.noBorder]} colSpan={4}>
              Individual Packages
            </StyledTableCell>
          </TableRow>
          {individualPackages.length > 0 ? (
            individualPackages.map((individualPackage) => {
              return (
                <TableRow key={individualPackage?.id}>
                  <StyledTableCell className={classes.textForGoals}>{individualPackage?.attributes?.name}</StyledTableCell>
                  <StyledTableCell align="center" className={classes.textForGoals}>
                    {getPackageModifiedTime(individualPackage)}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.textForGoals}>
                    {getPackagePrices(individualPackage)}
                  </StyledTableCell>
                  <StyledTableCell align="center" className={classes.textForGoals}>
                    <TernaryCheck
                      condition={state.activeTab === "Default"}
                      trueComponent={
                        <img
                          src={blackEditIconPng}
                          onClick={editDefaultPackage.bind(this,individualPackage)}
                          className={classes.editIconStyles}
                          alt="blackEditIconPng"
                        />
                      }
                      falseComponent={<NotInterestedIcon />}
                    />
                  </StyledTableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <StyledTableCell colSpan={4} align="center" className={classes.textForGoals}>
                No Packages Available
              </StyledTableCell>
            </TableRow>
          )}
          {state.activeTab === "Default" ? (
            <>
              <TableRow>
                <StyledTableCell
                  color="#f9f9f9"
                  className={[classes.columnsHeading, classes.noBorder]}
                  style={{
                    padding: "0.5rem 1rem",
                  }}
                  colSpan={3}
                >
                  <CustomGrid xs={12} container alignItems="center">
                    <CustomTypography className={classes.columnsHeading}>Custom Packages</CustomTypography>
                    <BootstrapTooltip
                      title="Custom Packages will not be included in your Firm Financials calculations."
                      placement="right"
                      color={theme.palette.text.secondary}
                    >
                      <IconButton disableFocusRipple disableTouchRipple disableRipple aria-label="delete">
                        <InfoOutlinedIcon
                          style={{
                            color: theme.palette.text.secondary,
                            fontSize: "1.3rem",
                          }}
                        />
                      </IconButton>
                    </BootstrapTooltip>
                  </CustomGrid>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  color="#f9f9f9"
                  className={[classes.columnsHeading, classes.noBorder]}
                  style={{
                    padding: "0.5rem 1rem",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      createCustomPackage();
                    }}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Create Custom Package
                  </Button>
                </StyledTableCell>
              </TableRow>
              {customPackages.length > 0 ? (
                customPackages.map((customPackage) => {
                  return (
                    <TableRow key={customPackage?.id}>
                      <StyledTableCell className={classes.textForGoals}>{customPackage?.attributes?.name}</StyledTableCell>
                      <StyledTableCell align="center" className={classes.textForGoals}>
                        {moment(customPackage?.attributes?.updated_at).format("MM/DD/YY | hh:mm a")}
                      </StyledTableCell>
                      <StyledTableCell align="center" className={classes.textForGoals}>
                        $ {formatCurrency.format(customPackage?.attributes?.payment_term?.total_amount || "NA")}
                      </StyledTableCell>
                      <StyledTableCell align="center" className={classes.textForGoals}>
                        <img
                          src={blackEditIconPng}
                          onClick={() => editCustomPackage(customPackage?.id)}
                          className={classes.editIconStyles}
                          style={{
                            marginLeft: "4rem",
                          }}
                          alt="blackEditIconPng"
                        />
                        <DeleteIcon color="error" className={classes.deleteIconStyles} onClick={() => openDeleteCustomPackageDialog(customPackage?.id)} />
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={4} align="center" className={classes.textForGoals}>
                    No Packages Available
                  </StyledTableCell>
                </TableRow>
              )}
              <TernaryCheck
                condition={isOnTrackToReachGoal()}
                trueComponent={
                  <TableRow>
                    <StyledTableCell colSpan={4} align="center">
                      <CustomTypography color="error" margin="1rem 0" fontWeight={600}>
                        You have made changes to one or more of the default packages. With the current modifications
                        <br />
                        you are no longer on track to reach your revenue goal.
                      </CustomTypography>
                    </StyledTableCell>
                  </TableRow>
                }
                falseComponent={null}
              />
            </>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export class FirmPricing extends FirmPricingController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    if (token) {
      this.setState(
        {
          token: token,
          accountId: accountId,
        },
        () => {
          this.getDefaultPackages();
          this.getDefaultFirmFinancialsByAccount();
        }
      );
    }
  }

  openDeleteCustomPackageDialog = (packageId) => {
    this.props
      .openDialogBox({
        dataToPass: {
          description: "Are you sure you want to delete this custom package?",
          yesButtonText: "Yes",
          noButtonText: "Cancel",
        },
        width: "45%",
        renderedComponent: ManageUsersCommonDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.deleteCustomPackage(packageId);
        }
      });
  };

  openRevertDefaultDialog = () => {
    this.props
      .openDialogBox({
        dataToPass: {
          description: "Are you sure you want revert back to system default pricing?",
          yesButtonText: "Yes",
          noButtonText: "Cancel",
        },
        width: "45%",
        renderedComponent: ManageUsersCommonDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.revertPackagePrice();
        }
      });
  };

  handleTabChange = (e, value) => {
    this.setState({
      activeTab: value,
    });
  };

  createCustomPackage = () => {
    this.props.history.push("/home/customPackageCreation");
  };

  editDefaultPackage = (defaultPackage: number) => {
    if (defaultPackage?.attributes?.custom_package_managements?.data?.id) {
      this.props.history.push({
        pathname: "/home/customPackageCreation",
        state: {
          id: defaultPackage?.attributes?.custom_package_managements?.data?.id,
          mode: "edit",
          package_type: "custom_package_management",
        },
      });
    } else {
      this.props.history.push({
        pathname: "/home/customPackageCreation",
        state: {
          id: defaultPackage?.id,
          mode: "edit",
          package_type: "package_management",
        },
      });
    }
  };

  editCustomPackage = (customPackage: number) => {
    this.props.history.push({
      pathname: "/home/customPackageCreation",
      state: {
        id: customPackage,
        mode: "edit",
        package_type: "custom_package_management",
      },
    });
  };

  getPackagePrices = (pkg) => {
    if (this.state.activeTab === "Default") {
      if (pkg.attributes.custom_package_managements.data != null) {
        return `$ ${formatCurrency.format(pkg.attributes.custom_package_managements.data.attributes.payment_term.total_amount)}`;
      } else {
        return `$ ${formatCurrency.format(pkg.attributes.total_contract)}`;
      }
    } else if (this.state.activeTab === "Goal") {
      return `$ ${formatCurrency.format(this.state.packageFirmPrices[pkg.attributes.name] || 0)}`;
    } else {
      return `$ ${formatCurrency.format(0)}`;
    }
  };

  getPackageModifiedTime = (pkg) => {
    if (pkg?.attributes?.custom_package_managements?.data?.id) {
      return moment(pkg?.attributes?.custom_package_managements?.data?.attributes?.updated_at).format("MM/DD/YY | hh:mm a");
    } else {
      return moment(pkg?.attributes?.package_created_at).format("MM/DD/YY | hh:mm a");
    }
  };

  isOnTrackToReachGoal = () => {
    let flag = 0;
    for (let pkg of this.state.defaultPackages) {
      if (pkg.attributes?.custom_package_managements?.data?.id) {
        flag = flag + 1;
      }
    }
    return flag > 0;
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <Grid container direction="row" alignItems="center">
        <Grid container direction="row" justifyContent="space-between" alignItems="center" item xs={12}>
          <CustomTypography color="primary" fontSize="1.625rem" margin="1rem 0" fontWeight={600}>
            Ideal Price Points
          </CustomTypography>
        </Grid>

        <CustomGrid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          padding="1rem 2rem"
          backgroundColor={theme.palette.primary.main}
          style={{
            borderRadius: "0.75rem",
          }}
        >
          <CustomTypography otherColor={theme.palette.common.white} fontSize="1.5rem" fontWeight={700}>
            Revenue Floor: $ {formatCurrency.format(this.state.defaultFirmFinancialsData?.attributes?.monthly_revenue_floor * 12 || 0)}
          </CustomTypography>
          <CustomTypography otherColor={theme.palette.common.white} fontSize="1.5rem" fontWeight={700}>
            Current Revenue: $ {formatCurrency.format(this.state.defaultFirmFinancialsData?.attributes?.total_revenue || 0)}
          </CustomTypography>
          <CustomTypography otherColor={theme.palette.common.white} fontSize="1.5rem" fontWeight={700}>
            Revenue Goal: $ 0.00
          </CustomTypography>
        </CustomGrid>

        <CustomGrid item xs={12} container alignItems="center" margin="1.5rem 0 0 0">
          <CustomTypography color="primary" fontSize="1.4rem" fontWeight={600}>
            Surplus/Deficit
          </CustomTypography>
          <BootstrapTooltip
            title="Your surplus or deficit equals the difference between your current revenue and your calculated Revenue Floor. Your Revenue Floor equals the amount needed to pay your expenses plus owner's compensation."
            placement="right"
            color={theme.palette.primary.main}
          >
            <IconButton disableFocusRipple disableTouchRipple disableRipple aria-label="delete">
              <InfoOutlinedIcon
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "1.3rem",
                }}
              />
            </IconButton>
          </BootstrapTooltip>
        </CustomGrid>

        <Grid item xs={12} className={classes.cardPaper}>
          <Grid container direction="row" spacing={1} justifyContent="space-around">
            <Grid item xs={12} container alignItems="center" justifyContent="space-between">
              <Typography display="inline" className={classes.goalcardHeading}>
                Your Price Points
              </Typography>
              <CustomGrid item xs={6} container justifyContent="flex-end" margin="0 0 1rem 0">
                <Tabs
                  value={this.state.activeTab}
                  onChange={this.handleTabChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  classes={{
                    indicator: classes.tabIndicator,
                  }}
                >
                  <Tab
                    label="Default"
                    value="Default"
                    classes={{
                      root: classes.tabRootStyles,
                    }}
                  />
                  <Tab
                    label="Floor"
                    value="Floor"
                    classes={{
                      root: classes.tabRootStyles,
                    }}
                  />
                  <Tab
                    label="Goal"
                    value="Goal"
                    classes={{
                      root: classes.tabRootStyles,
                    }}
                  />
                </Tabs>
              </CustomGrid>
            </Grid>
            <Grid item xs={12}>
              <PricingDataTable
                classes={classes}
                theme={theme}
                state={this.state}
                props={this.props}
                customPackages={this.state.customPackages}
                createCustomPackage={this.createCustomPackage.bind(this)}
                defaultPackages={this.state.defaultPackages}
                editDefaultPackage={this.editDefaultPackage.bind(this)}
                editCustomPackage={this.editCustomPackage.bind(this)}
                openDeleteCustomPackageDialog={this.openDeleteCustomPackageDialog.bind(this)}
                getPackagePrices={this.getPackagePrices}
                getPackageModifiedTime={this.getPackageModifiedTime}
                isOnTrackToReachGoal={this.isOnTrackToReachGoal}
              />

              <CustomGrid container justifyContent="space-between" margin="2rem 0">
                <CustomGrid item xs={4} width="29%" container alignItems="center">
                  <Button color="primary" variant="outlined" fullWidth onClick={this.openRevertDefaultDialog}>
                    Revert Back to System Default Pricing
                  </Button>
                </CustomGrid>

                <CustomGrid alignItems="center" width="9%" item xs={2} container justifyContent="space-between" margin="0 2rem 0 auto">
                  <Button color="primary" variant="outlined" fullWidth onClick={() => {}}>
                    Cancel
                  </Button>
                </CustomGrid>

                <CustomGrid alignItems="center" width="19%" item xs={2} container justifyContent="space-between">
                  <Button color="primary" fullWidth variant="contained" type="submit" id="firmFinancials-submitButton">
                    Apply Changes
                  </Button>
                </CustomGrid>
              </CustomGrid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const FirmPricingWithRouter = withRouter(FirmPricing);
const FirmPricingAlertBox = withAlertBox(FirmPricingWithRouter);
const FirmPricingLoader = withLoader(FirmPricingAlertBox);
const FirmPricingToast = withToast(FirmPricingLoader);
const FirmPricingWithDialog = withDialog(FirmPricingToast);

export default withStyles(
  (theme) =>
    createStyles({
      input: {
        "&:invalid": {
          border: "red solid 2px",
        },
      },
      headingH2: {
        fontSize: "1.125rem",
        paddingLeft: 5,
        fontWeight: 600,
      },
      headingH22: {
        fontSize: "1.125rem",
        // paddingLeft: 5,
        fontWeight: 600,
        color: "#282c2c",
        marginTop: theme.spacing(4),
      },
      pushingLeft: {
        paddingRight: 20,
        color: theme.palette.text.secondary,
      },
      root: {
        height: "calc(100vh - 70px)!important",
        overflowX: "hidden",
      },
      flexEnd: {
        textAlign: "end",
        [theme.breakpoints.down("xs")]: {
          width: "30%",
        },
      },
      fontSizeSS: {
        fontSize: "0.875rem",
        fontWeight: 400,
      },
      packageFont: {
        fontSize: "1rem",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down("md")]: {
          padding: "16px",
        },
      },
      blueText: {
        color: theme.palette.text.secondary,
        fontWeight: 600,
      },
      blueTextTotal: {
        "& .MuiTypography-body1": {
          fontSize: "1.125rem!important",
          fontWeight: "bold",
          color: theme.palette.text.secondary,
        },
      },
      fontSizeS: {
        fontSize: "0.875rem",
        // fontWeight: "bold",
        "& input": {
          fontSize: "0.875rem",
        },
        color: theme.palette.text.secondary,
      },
      blueTextField: {
        fontSize: "0.875rem",
        "& input": {
          color: theme.palette.text.secondary,
          fontSize: "0.875rem",
        },
      },
      blueTextGoals: {
        fontSize: "0.875rem",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.9375rem",
        },
        color: theme.palette.text.secondary,
        fontWeight: 600,
      },
      textForGoals: {
        fontSize: "0.9375rem",
        color: theme.palette.text.secondary,
        fontWeight: 600,
      },
      blueBoldTextExpense: {
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
        fontWeight: 600,
        [theme.breakpoints.down("md")]: {
          fontSize: "0.9375rem",
        },
      },
      fontSizeTS: {
        fontSize: "0.75rem",
      },
      fontBold: {
        fontWeight: "bold",
      },
      numberField: {
        width: "50%",
      },
      numberFieldSmall: {
        width: "30%",
      },
      cardPaper: {
        [theme.breakpoints.up("md")]: {
          margin: theme.spacing(2, 0),
        },
        margin: theme.spacing(1, 0),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      card: {
        padding: theme.spacing(4),
        borderRadius: "8px",
        border: "solid 1px #e5eaf4",
        backgroundColor: "#ffffff",
        boxShadow: "0 15px 35px 0 rgba(0, 0, 0, 0.05)",
        height: theme.spacing(25),
        width: "96%",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
          width: "98%",
          margin: theme.spacing(2, 0),
        },
      },
      cardImage: {
        padding: theme.spacing(1, 1),
        width: "100%",
        textAlign: "center",
      },
      cardTitle: {
        fontFamily: "Lato",
        fontSize: "1.25rem",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        textAlign: "center",
      },
      blueBoxGoals: {
        backgroundColor: theme.palette.text.blue2,
        marginTop: "1.5rem",
        color: "#fff",
        borderRadius: "10px",
      },
      buttonFont: {
        fontSize: 18,
      },
      goalcardHeading: {
        fontFamily: "Lato",
        fontSize: "1.625rem",
        fontStyle: "normal",
        letterSpacing: "normal",
        color: theme.palette.text.blue2,
      },
      noBorderInputField: {
        border: "none",
        "&:active": {
          border: "none",
        },
      },
      columnsHeading: {
        fontFamily: "Lato",
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        fontWeight: "bold",
      },
      columnsHeadingForClientInfo: {
        fontFamily: "Lato",
        fontSize: "0.875rem",
        color: theme.palette.text.secondary,
        fontWeight: 600,
      },
      columnsBodyForClientInfo: {
        fontSize: "0.875rem",
      },
      columnsHeadingForGoal: {
        fontFamily: "Lato",
        color: theme.palette.text.secondary,
        fontSize: "1rem",
        fontWeight: "bold",
        "&:first-of-type": {
          borderTopLeftRadius: "1rem",
          borderBottomLeftRadius: "1rem",
        },
        "&:last-of-type": {
          borderTopRightRadius: "1rem",
          borderBottomRightRadius: "1rem",
        },
      },
      mainContainer: {
        padding: theme.spacing(6.2),
      },
      threeSmallCardsHeading: {
        color: "#fff",
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginTop: "2rem",
        marginBottom: "1rem",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.8125rem",
        },
      },
      threeSmallCardsPrice: {
        color: "#fff",
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginBottom: "2rem",
        [theme.breakpoints.down("md")]: {
          fontSize: "0.9375rem",
        },
      },
      clientInfoPrice: {
        color: theme.palette.text.secondary,
        fontSize: "0.875rem",
        fontWeight: 600,
      },
      revenueTotalLarge: {
        color: theme.palette.text.blue2,
        fontSize: "1.2rem",
        fontWeight: "bold",
      },
      clientInfoUnitOrMonth: {
        color: theme.palette.text.secondary,
        fontSize: "0.875rem",
        fontWeight: 600,
      },
      primaryGoalName: {
        fontSize: "0.9375rem",
        fontWeight: 600,
        color: theme.palette.text.secondary,
      },
      boxOfArr: {
        backgroundColor: "#f4f4f4",
        color: "white",
        padding: "0.5rem",
        borderRadius: "0.5rem",
      },
      arrMrrText: {
        display: "inline-block",
        fontSize: "1rem",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
      },
      totalRevenueText: {
        display: "inline-block",
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },
      clientInfoSectionHeaders: {
        padding: "1rem",
        fontSize: "1.2rem",
        fontWeight: "bold",
        color: theme.palette.text.primary,
        border: "none",
      },
      noBorder: {
        border: "none",
      },
      tabRootStyles: {
        height: "auto",
        fontSize: "1.2rem",
        color: theme.palette.text.secondary,
        fontWeight: 600,
        margin: 0,
        padding: "0 1rem",
        borderRight: `0.5px solid ${theme.palette.text.blue2}`,
        borderLeft: `0.5px solid ${theme.palette.text.blue2}`,
        "&:first-of-type": {
          borderRight: `0.5px solid ${theme.palette.text.blue2}`,
          borderLeft: 0,
        },
        "&:last-of-type": {
          borderLeft: `0.5px solid ${theme.palette.text.blue2}`,
          borderRight: 0,
        },
        "& .Mui-selected": {
          fontSize: "1.2rem",
          color: theme.palette.text.blue2,
          fontWeight: 600,
          margin: 0,
          padding: "0 1rem",
        },
      },
      tabIndicator: {
        display: "none",
      },
      editIconStyles: {
        cursor: "pointer",
        height: "1.5rem",
      },
      deleteIconStyles: {
        height: "1.5rem",
        width: "1.5rem",
        marginLeft: "3rem",
        cursor: "pointer",
      },
    }),
  { withTheme: true }
)(withConfirmBox(FirmPricingWithDialog));
