export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");
export const checkMark = require("../assets/checkMark.png");
export const blankCircle = require("../assets/24596aa1bcbab716ccb4e6bc7404fe2e4592865e.png");
export const profile = require("../assets/profile.png");
export const startRoadMap = require("../assets/startRoadMap.png");
export const training = require("../assets/training.png");
export const viewRoadMap = require("../assets/viewRoadMap.png");
export const account = require("../assets/account.png");
export const content = require("../assets/content.png");
export const role = require("../assets/role.png");
export const edit = require("../assets/edit.png");
export const deleteIcon = require("../assets/d61ca216d7626c6a180edd8b7467173b32434640.png");
export const LinkIcon = require("../assets/linkIcon.png");
export const imageOfImage = require("../assets/0937e2df00dec93b5a6feecbdcc621dc27832c38.png");
export const packageSelect = require("../assets/packageSelect.png");
export const packageunSelect = require("../assets/packageunSelect.png");
export const checkbox = require("../assets/checkboxwhite.png");
export const googleDrive = require("../assets/drive.png");

//phase 2 imports

export const uploadBoxImage = require("../assets/phase2/img.png");
export const zoomIcon = require("../assets/phase2/Zoom.png");
export const priceUp = require("../assets/phase2/up.png");
export const priceDown = require("../assets/phase2/Down.png");
export const toggleIn = require("../assets/phase2/in.png");
export const toggleOut = require("../assets/phase2/Out.png");
export const profileImage = require("../assets/phase2/profile.png");
export const editProfileImage = require("../assets/phase2/Edit.png");
export const closeIcon = require("../assets/phase2/close.png");
export const profileActive = require("../assets/phase2/profileActive.png");
export const lockActive = require("../assets/phase2/lockActive.png");
export const profileInactive = require("../assets/phase2/profileInactive.png");
export const lockInactive = require("../assets/phase2/lockInactive.png");
export const checkMarkPhaseTwo = require("../assets/phase2/checkMarkPhaseTwo.png");
export const infoIcon = require("../assets/phase2/information.png");
export const viewIcon = require("../assets/phase2/View.png");
export const moreIcon = require("../assets/phase2/moreIcon.png");
export const askQuestion = require("../assets/phase2/askQuestion.png");
export const shareIcon = require("../assets/phase2/send.png");
export const eyeIcon = require("../assets/phase2/View.png");

//Survey Icons
export const Business = require("../assets/Business.png");
export const CareerNeeds = require("../assets/CareerNeeds.png");
export const Cashflow = require("../assets/Cashflow.png");
export const Goals = require("../assets/Goals.png");
export const Reporting = require("../assets/Reporting.png");
export const Savings = require("../assets/Savings.png");
export const TaxSaving = require("../assets/TaxSaving.png");

export const Back = require("../assets/back.png");
export const Next = require("../assets/Next.png");
export const CpaCharge = require("../assets/CPACharg.png");
export const calendly = require("../assets/calendly1.png");
export const blackEditIconPng = require("../assets/blackEditPng.png");
//packageImage

export const accountingSuccess = require("../assets/phase2/packageImage/accounting_success.png");
export const businessBundle = require("../assets/phase2/packageImage/business_bundle.png");
export const businessTaxAdvisory = require("../assets/phase2/packageImage/business_taxadvisory.png");
export const irsRapidRelief = require("../assets/phase2/packageImage/irs_rapid_relief.png");
export const newBusinessIncubator = require("../assets/phase2/packageImage/new_business_incubator.png");
export const payrollSuccess = require("../assets/phase2/packageImage/payroll_success.png");
export const personalBundle = require("../assets/phase2/packageImage/personal_bundle.png");
export const reportingOnly = require("../assets/phase2/packageImage/reporting_only_business.png");
export const customPackage = require("../assets/phase2/packageImage/customPackage.png");

export const calendlyIntegrations = require("../assets/phase2/calendly.png");
export const cpaChargeIntegrations = require("../assets/phase2/cpaCharge.png");
export const googleDriveIntegrations = require("../assets/phase2/googleDrive.png");
export const CheckIcon=require("../assets/checkIcon.png")

export const CustomPackageFromScratch = require("../assets/customPackageFromScratch.png");
export const CustomPackagefromSelectedPackage = require("../assets/CustomPackagefromSelectedPackage.png");

export const CustomPackageFromScratchSelected = require("../assets/customPackageFromScratchSelected.png");
export const CustomPackagefromSelectedPackageSelected = require("../assets/CustomPackagefromSelectedPackageSelected.png");