//@ts-nocheck

import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../../components/src/withLoader.Web";
import { DialogProps } from "../../../../components/src/DialogContext";

export const configJSON = require("../config");

export type Props = RouteComponentProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  token: string;
  accountId: any;
  profile: any;
  selectedTab: string;
  clientStatus: string;
  revenueTimePeriod: string;
  singleClient: object;
  clientId: number;
  defaultLogoImageFromServer: any;
  surveyList: any;
  prioritiesList: object[];
  proposals: [];
  document_templates: [];
  isAutoReminder: boolean;
  enrolledPackages: [];
  expandProfile: boolean;
  linkClientField: string;
  clientoptions: any[];
  selectedClientForlink: any;
  isSearchFieldVisible: boolean;
  seatchLoading : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SingleClientController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSingleClientApiCallId: string = "";
  updateSingleClientApiCallId: string = "";
  sendCFSSurveyCallId: string = "";
  getSurveyListByClientIdApiCallId: string = "";
  addPriorityApiCallId: string = "";
  getPrioritiesApiCallId: string = "";
  deletePriorityApiCallId: string = "";
  updatePriorityApiCallId: string = "";
  dragPriorityApiCallId: string = "";
  linkClientApiCallId: string = "";
  searchClientApiCallId: string = "";
  updateStatusApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      token: "",
      accountId: "",
      profile: {},
      selectedTab: "clientOverview",
      clientStatus: "",
      revenueTimePeriod: "Last 12 Months",
      singleClient: {},
      clientId: "",
      defaultLogoImageFromServer: "",
      surveyList: [],
      prioritiesList: [],
      proposals: [],
      document_templates: [],
      isAutoReminder: false,
      enrolledPackages: [],
      expandProfile: false,
      linkClientField: "",
      clientoptions: [],
      selectedClientForlink: null,
      isSearchFieldVisible: false,
      seatchLoading : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        
        case this.getSingleClientApiCallId:
          this.handleSingleClient(responseJson);
          break;
        case this.sendCFSSurveyCallId:
          this.handleSendSurveyResponse(responseJson);
          break;
        case this.uploadPackageImageApiCallId:
          this.handleUploadPackageImageResponse(responseJson);
          break;
        case this.getSurveyListByClientIdApiCallId:
          this.handleSurveyListResponse(responseJson);
          break;
        case this.getPrioritiesApiCallId:
          this.handlePriortiesListResponse(responseJson);
          break;
        case this.addPriorityApiCallId:
          this.handleAddPriorityResponse(responseJson);
          break;
        case this.updatePriorityApiCallId:
          this.handleUpdatedPriorityResponse(responseJson);
          break;
        case this.deletePriorityApiCallId:
          this.handleDeletePriorityResponse(responseJson);
          break;
        case this.dragPriorityApiCallId:
          this.handleDragPriorityResponse(responseJson);
          break;
        
        case this.sendReminderEmailApiCallId:
          this.handleReminderEmailResponse(responseJson);
          break;
        case this.setupAutoReminderApiCallId:
          this.handleSetupAutoReminderResponse(responseJson);
          break;
        case this.viewSignedDocumentApiCallId:
          this.handleViewSignedDocumentResponse(responseJson);
          break;
        case this.searchClientApiCallId:
          this.handleSearchClientResponse(responseJson);
          break;
        case this.linkClientApiCallId:
          this.handleLinkedClientResponse(responseJson);
          break;
        case this.updateStatusApiCallId:
          this.handleUpdatedProfileResponse(responseJson);
          break;
        default:
          break;
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const AlertBodyMessage = message.getData(
        getName(MessageEnum.AlertBodyMessage)
      );
      const msg = await this.props.showAlert({
        title: "Error",
        message: AlertBodyMessage,
      });
      if (msg === 'Session TimeOut,Please Login Again.' || msg === 'User Account Not Found') {
        await StorageProvider.remove("authToken");
        await StorageProvider.remove("accountId");
        this.props.history.push("/");
      }
    }
  }
  handleUpdatedProfileResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data) {
      this.props.showToast({
        type: "success",
        message: "Profile updated successfully",
      });
      this.getSingleClient();
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleLinkedClientResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.props.showToast({
        type: "success",
        message: "Client linked successfully",
      });
      this.setState(
        {
          selectedClientForlink: null,
          isSearchFieldVisible: false,
          clientoptions: [],
        },
        () => {
          this.getSingleClient();
        }
      );
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleSearchClientResponse = (responseJson) => {
    let searchClientList =
      responseJson?.data?.filter((client) => {
        return (
          this.state.singleClient?.attributes?.linked_clients
            .map((cl) => {
              return cl.id + "";
            })
            .indexOf(client?.id) < 0
        );
      }) || [];
    searchClientList = searchClientList?.filter((client) => {
      return client.id != this.state.singleClient?.id;
    });
    this.setState({
      clientoptions: searchClientList?.length > 0 ? searchClientList : [{attributes:{first_name : `No client found with name ${this.state.linkClientField}`,noData : true}}],
      seatchLoading : false      
    });
  };
  handleViewSignedDocumentResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.file_url) {
      window.open(responseJson?.file_url);
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleReminderEmailResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message) {
      this.props.showToast({
        type: "success",
        message: "Reminder sent successfully",
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleSetupAutoReminderResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.id) {
      this.props.showToast({
        type: "success",
        message: "Auto Reminder setup successfully",
      });
      this.setState({
        isAutoReminder: responseJson?.data?.attributes?.is_reminder,
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleApiErrorResponse = (responseJson) => {
    if (typeof responseJson?.errors === "string") {
      this.props.showToast({
        type: "error",
        message: responseJson.errors,
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };
  
  
  handleSingleClient = async (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data && responseJson?.data?.attributes) {
      let modifiedClient = {
        ...responseJson?.data,
        attributes: {
          ...responseJson?.data.attributes,
          profile_status: responseJson?.data.attributes.profile_status
            ? responseJson?.data.attributes.profile_status
            : "Contact",
          client_type: responseJson?.data.attributes.client_type
            ? responseJson?.data.attributes.client_type
            : "Individual",
        },
      };
      this.setState(
        {
          singleClient: modifiedClient,
          document_templates:
            responseJson?.data?.attributes?.document_templates || [],
          proposals: responseJson?.data?.attributes?.proposals,
          isAutoReminder: responseJson?.data?.attributes?.is_reminder,
          enrolledPackages: responseJson?.data?.attributes?.packages || [],
          primaryColor : responseJson?.data?.attributes?.road_customization?.data?.attributes?.add_primary_color,
          secondaryColor : responseJson?.data?.attributes?.road_customization?.data?.attributes?.add_secondary_color
        },
        () => {
          this.getPriorities(this.state.singleClient.id);
        }
      );
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleSendSurveyResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.message) {
      this.props.showToast({
        type: "success",
        message: "Survey sent successfully",
      });
    } else {
      this.handleSendSurveyApiErrorResponse(responseJson);
    }
  };
  handleSendSurveyApiErrorResponse = (responseJson) => {
    const errors = responseJson?.errors;
    if (errors?.checklists?.length > 0) {
      this.props.showToast({
        type: "error",
        message: `please assign checklist for all packages`,
      });
      this.props.history.push("/home/settings/manageDeliveryChecklist");
    } else if (errors?.documents?.length > 0) {
      this.props.showToast({
        type: "error",
        message: `please assign documents for all packages`,
      });
      this.props.history.push("/home/settings/documentTemplates");
      return false;
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleUploadPackageImageResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      let modifiedClient = {
        ...responseJson?.data,
        attributes: {
          ...responseJson?.data.attributes,
          profile_status: responseJson?.data.attributes.profile_status
            ? responseJson?.data.attributes.profile_status
            : "Contact",
          client_type: responseJson?.data.attributes.client_type
            ? responseJson?.data.attributes.client_type
            : "Individual",
        },
      };
      this.setState(
        {
          singleClient: modifiedClient,
        },
        () => {
          this.props.showToast({
            type: "success",
            message: "Profile updated Successfully",
          });
        }
      );
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleSurveyListResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson?.data?.length > 0) {
      this.setState({
        surveyList: responseJson?.data,
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handlePriortiesListResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      let prio = responseJson?.data.map((item, index) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            rank: index,
          },
        };
      });
      this.setState({
        prioritiesList: prio,
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleAddPriorityResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data && responseJson?.data?.attributes) {
      let prioritiesListTemp = [...this.state.prioritiesList];

      let newItem = {
        ...responseJson.data,
        attributes: {
          ...responseJson.data.attributes,
          rank: prioritiesListTemp.length,
        },
      };

      prioritiesListTemp.push(newItem);
      this.setState({
        prioritiesList: prioritiesListTemp,
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleUpdatedPriorityResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      // this.setState({
      //   prioritiesList: responseJson?.data
      // })
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleDeletePriorityResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      let prio = responseJson?.data.map((item, index) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            rank: index,
          },
        };
      });
      this.setState({
        prioritiesList: prio,
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };
  handleDragPriorityResponse = (responseJson) => {
    this.props.hideLoader();
    if (responseJson && responseJson?.data) {
      let prio = responseJson?.data.map((item, index) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            rank: index,
          },
        };
      });
      this.setState({
        prioritiesList: prio,
      });
    } else {
      this.handleApiErrorResponse(responseJson);
    }
  };

  

  getSingleClient = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSingleClientApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/client_informations/${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendCFSSurvey = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };
    const survey = {
      id: this.state.clientId,
      survey_link: window.location.origin + "/surveyLandingPage",
      access_token: localStorage.getItem("paymentAccessToken") || undefined,
      account_id: this.state.accountId,
      type: "manually",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendCFSSurveyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/send_survey`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(survey)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  uploadPackageImage = (values: any) => {
    this.props.showLoader();
    const headers = {
      token: this.state.token,
      "content-type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadPackageImageApiCallId = requestMessage.messageId;

    const formData = {
      avatar: values?.file,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  updateStatus = (values: any) => {
    this.props.showLoader();
    const headers = {
      token: this.state.token,
      "content-type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateStatusApiCallId = requestMessage.messageId;

    const formData = {
      profile_status: this.state.clientStatus,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(formData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSurveyListByClientId = () => {
    this.props.showLoader();
    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSurveyListByClientIdApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_survey/surveys?client_id=${this.state.clientId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  getPriorities = (clientId) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrioritiesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/${clientId}/priorities`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addPriority = (clientId, priorityName) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addPriorityApiCallId = requestMessage.messageId;

    const httpBody = {
      priorities: {
        name: priorityName,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/${clientId}/priorities`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updatePriority = (statusValue, clientId, priorityId) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePriorityApiCallId = requestMessage.messageId;

    const httpBody = {
      priorities: {
        status: statusValue,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/${clientId}/priorities/${priorityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deletePriority = (clientId, priorityId) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePriorityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/${clientId}/priorities/${priorityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  dragPriority = (clientId, prioritiesList) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const priorityIds = prioritiesList.map((item) => {
      return +item.id;
    });

    const httpBody = {
      ids: priorityIds,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.dragPriorityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/${clientId}/priorities/update_ranks`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  sendReminderEmail = (survey_id, package_management_id, client_id) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const httpBody = {
      id: this.state.clientId,
      onboarding_link: `${
        window.location.origin
      }/onboardingSurveyLandingPage?survey_id=${survey_id}&package_management_id=${package_management_id}&client_id=${client_id}&account_id=${
        this.state.accountId
      }&selected_package_id=${package_management_id}&allow_to_change_package_id=${false}`,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendReminderEmailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/proposal_pending_remainder`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  setupAutoReminder = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const httpBody = {
      id: this.state.clientId,
      is_reminder: this.state.isAutoReminder,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setupAutoReminderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_clients/client_informations/client_auto_reminder`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  viewSignedDocument = (signature_request_id) => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewSignedDocumentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `client_surveys/view_document?signature_request_id=${signature_request_id?.signature_request}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  linkClient = () => {
    this.props.showLoader();

    const headers = {
      "content-type": "application/json",
      token: this.state.token,
    };

    const httpBody = {
      linked_client: {
        client_information_id: this.state.clientId,
        linked_client_id: this.state.selectedClientForlink?.id,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.linkClientApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_pricingcalculator/linked_clients`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  searchClient = (searchValue: string) => {
    this.setState(
      {
        seatchLoading: true,
      },
      () => {
        let url = `bx_block_pricingcalculator/client_informations?query=${searchValue}`;

        const headers = {
          "content-type": "application/json",
          token: this.state.token,
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.searchClientApiCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          url
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    );
  };
  debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
}
