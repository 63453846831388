//@ts-nocheck
import React from "react";
import bulbIcon from "../../assets/13b944b8c44d78a19696d4183e2f4b0edab1b89f.png";
import {
  Typography,
  Grid,
  withStyles,
  createStyles,
  List,
  ListItem,
  ListItemText,
  Button,
  RadioGroup,
  Radio,
  Divider,
  CheckboxProps,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter, RouterProps } from "react-router";
import withAlertBox, { withAlertBoxProps } from "../../../../components/src/withAlertBox.Web";
import withToast, { withToastProps } from "../../../../components/src/withSnackBar.Web";
import withLoader, { withLoaderProps } from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import withConfirmBox from "../../../../components/src/withConfirmBox.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import InputField from "../../../../components/src/InputFieldPhaseTwo.web";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { DialogProps } from "../../../../components/src/DialogContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "underscore";

export type Props = RouterProps &
  DialogProps &
  withAlertBoxProps &
  withToastProps &
  withLoaderProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

const clientInformationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z\s-]+$/, "Only alphabets are allowed for this field")
    .required("This field is required."),
  lastName: Yup.string()
    .matches(/^[A-Za-z\s-]+$/, "Only alphabets are allowed for this field")
    .required("This field is required."),
  spouseFirstName: Yup.string().matches(/^[A-Za-z\s-]+$/, "Only alphabets are allowed for this field"),
  spouseLastName: Yup.string().matches(/^[A-Za-z\s-]+$/, "Only alphabets are allowed for this field"),
  primaryEmail: Yup.string()
    .email("Email must be in valid format!")
    .required("This field is required."),
  secondaryEmail: Yup.string().when("primaryEmail", (primaryEmail) => {
    if (primaryEmail) {
      return Yup.string()
        .email("Email must be in valid format!")
        .notOneOf([Yup.ref("primaryEmail"), null], "Secondary email can't be same as primary email.");
    }
  }),
  primaryPhone: Yup.string()
    .matches(/^(\+\d{0,3})?(\(\d{3}\)|\d{3})[\s-]??\d{3}-??\d{4}$/, "Invalid Phone number")
    .nullable(),
  secondaryPhone: Yup.string().when("primaryPhone", (primaryPhone) => {
    if (primaryPhone) {
      return Yup.string()
        .notOneOf([Yup.ref("primaryPhone"), null], "Secondary phone can't be same as primary phone.")
        .matches(/^(\+\d{0,3})?(\(\d{3}\)|\d{3})[\s-]??\d{3}-??\d{4}$/, "Invalid Phone number");
    }
  }),
  notes: Yup.string(),
  input1: Yup.string(),
  input2: Yup.string(),
  input3: Yup.string(),
  input5: Yup.string(),
  firstPriority: Yup.string(),
  secondPriority: Yup.string(),
  thirdPriority: Yup.string(),
  selectedClient: Yup.object({
    full_name: Yup.string().matches(/^[A-Za-z\s-]+$/, "Only alphabets are allowed for this field"),
  }),
});

const BootstrapTooltip = withStyles((theme: Theme) =>
  createStyles({
    arrow: {
      color: "#fff",
    },
    tooltip: {
      boxShadow: "rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px",
      padding: "24px",
      fontFamily: "Lato",
      fontSize: "1.2rem",
      backgroundColor: "#FEFEFE",
      borderRadius: "12px",
    },
  })
)(Tooltip);

const QuizDialog = ({ classes }) => {
  return (
    <Grid container justifyContent="space-between" className={classes.dialogBox}>
      <Grid xs={8}>
        <Typography className={classes.tooltipHeading}>Need help selecting a package for this client?</Typography>
      </Grid>
      <Grid item container justifyContent="center" alignItems="center" xs={4}>
        <img className={classes.bulbIcon} src={bulbIcon} />
      </Grid>
    </Grid>
  );
};

const GreenCheckbox = withStyles({
  root: {
    marginLeft: "10px",
    color: "#89c0c1",
    "&$checked": {
      color: "#89c0c1",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const StyledListItemText = withStyles((theme: Theme) =>
  createStyles({
    primary: {
      fontFamily: "Lato",
      fontSize: "1.75rem",
      color: "#54A4A6",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      marginBottom: theme.spacing(2),
    },
    secondary: {
      fontFamily: "Lato",
      fontSize: "1rem",
      color: "#747474",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
  })
)(ListItemText);

export class ClientInfo extends React.Component {
  constructor(props: Props) {
    super(props);

    this.searchClientDebounced = _.debounce(this.props.searchClient, 1200);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.props.getRoadMap(this.props?.roadMap?.id);
    }
  }

  autoCompleteOnChange = (setFieldValue, event, newValue) => {
    setFieldValue("selectedClient", newValue);
    setFieldValue("firstName", newValue.first_name || "");
    setFieldValue("lastName", newValue.last_name || "");
    setFieldValue("primaryEmail", newValue.primary_email || "");
    setFieldValue("secondaryEmail", newValue.secondary_email || "");
    setFieldValue("primaryPhone", newValue.primary_phone || "");
    setFieldValue("secondaryPhone", newValue.secondary_phone || "");
    setFieldValue("spouseFirstName", newValue.spouse_first_name || "");
    setFieldValue("spouseLastName", newValue.spouse_last_name || "");    
  };

  getAutoCompleteOptions = () => {
    const autoCompleteOptions = [];

    for (const item of this.props.searchedClientsList) {
      const full_name = `${item.first_name} ${item.last_name}`;
      const modifiedItem = { ...item, full_name };
      autoCompleteOptions.push(modifiedItem);
    }
    return autoCompleteOptions;
  };

  handleAutoCompleteInputChange = (fieldName, setFieldValue, event, newInputValue) => {
    setFieldValue(fieldName, newInputValue);
    const searchParams = {
      firstName: fieldName === "firstName" ? newInputValue : "",
      lastName: fieldName === "lastName" ? newInputValue : "",
    };
    this.searchClientDebounced(searchParams);
  };

  renderAutoCompleteOption = (option: any) => {
    return <div>{option.full_name}</div>;
  };

  handleBlur = (fieldName, setFieldTouched) => {
    setFieldTouched(fieldName, true);
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <Formik
        initialValues={{
          firstName: this.props.clientInformation?.attributes?.first_name || "",
          lastName: this.props.clientInformation?.attributes?.last_name || "",
          spouseFirstName: this.props.clientInformation?.attributes?.spouse_first_name || "",
          spouseLastName: this.props.clientInformation?.attributes?.spouse_last_name || "",
          primaryEmail: this.props.clientInformation?.attributes?.primary_email || "",
          secondaryEmail: this.props.clientInformation?.attributes?.secondary_email || "",
          primaryPhone: this.props.clientInformation?.attributes?.primary_phone || "",
          secondaryPhone: this.props.clientInformation?.attributes?.secondary_phone || "",
          notes: this.props.clientInformation?.attributes?.notes || "",
          input1: this.props.clientInformation?.attributes?.client_discovery?.inputField1 || "",
          input2: this.props.clientInformation?.attributes?.client_discovery?.inputField2,
          input3: this.props.clientInformation?.attributes?.client_discovery?.inputField3,
          input5: this.props.clientInformation?.attributes?.client_discovery?.inputField5,
          firstPriority: this.props.roadMap?.attributes?.priorities?.data[0]?.attributes?.name,
          secondPriority: this.props.roadMap?.attributes?.priorities?.data[1]?.attributes?.name,
          thirdPriority: this.props.roadMap?.attributes?.priorities?.data[2]?.attributes?.name,
          selectedClient: {
            first_name: this.props.clientInformation?.attributes?.first_name || "",
            last_name: this.props.clientInformation?.attributes?.last_name || "",
            full_name: "",
          },
        }}
        enableReinitialize
        validationSchema={clientInformationSchema}
        onSubmit={this.props.createAndUpdateClientInformation}
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue, isValid, errors, touched, setFieldTouched } = formikProps;

          return (
            <Form autoComplete="off" noValidate>
              <Grid container direction="row">
                <Grid item xs={12} md={12}>
                  <ListItem>
                    <StyledListItemText
                      primary="Client Information"
                      secondary="Add the client details for this Roadmap Proposal"
                      classes={{
                        secondary: classes.secondaryBlueText,
                      }}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container justifyContent="space-between">
                    <Grid item md={5} className={classes.bottomMargin} xs={12}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        noOptionsText="No Client Available"
                        name="firstName"
                        value={values.selectedClient}
                        onChange={this.autoCompleteOnChange.bind(this, setFieldValue)}
                        inputValue={values.firstName}
                        onInputChange={this.handleAutoCompleteInputChange.bind(this, "firstName", setFieldValue)}
                        options={this.getAutoCompleteOptions()}
                        getOptionLabel={(option) => {
                          return option.first_name || "";
                        }}
                        renderOption={this.renderAutoCompleteOption}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="First Name"
                              variant="outlined"
                              required
                              error={errors.firstName && touched.firstName}
                              helperText={errors.firstName && touched.firstName ? errors.firstName : ""}
                              onBlur={this.handleBlur.bind(this, "firstName", setFieldTouched)}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={5} className={classes.bottomMargin} xs={12}>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        noOptionsText="No Client Available"
                        name="lastName"
                        value={values.selectedClient}
                        onChange={this.autoCompleteOnChange.bind(this, setFieldValue)}
                        inputValue={values.lastName}
                        onInputChange={this.handleAutoCompleteInputChange.bind(this, "lastName", setFieldValue)}
                        options={this.getAutoCompleteOptions()}
                        getOptionLabel={(option) => {
                          return option.last_name || "";
                        }}
                        renderOption={this.renderAutoCompleteOption}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label="Last Name"
                              variant="outlined"
                              required
                              error={errors.lastName && touched.lastName}
                              helperText={errors.lastName && touched.lastName ? errors.lastName : ""}
                              onBlur={this.handleBlur.bind(this, "lastName", setFieldTouched)}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={5} className={classes.bottomMargin} xs={12}>
                      <Field
                        component={InputField}
                        onChange={handleChange}
                        name="spouseFirstName"
                        value={values.spouseFirstName}
                        fullWidth
                        label="Spouse First Name"
                      />
                    </Grid>
                    <Grid item md={5} className={classes.bottomMargin} xs={12}>
                      <Field
                        component={InputField}
                        onChange={handleChange}
                        name="spouseLastName"
                        value={values.spouseLastName}
                        fullWidth
                        label="Spouse Last Name"
                      />
                    </Grid>
                    <Grid item md={5} className={classes.bottomMargin} xs={12}>
                      <Field
                        component={InputField}
                        onChange={handleChange}
                        name="primaryEmail"
                        value={values.primaryEmail}
                        fullWidth
                        label="Primary Email"
                        required
                      />
                    </Grid>
                    <Grid item md={5} className={classes.bottomMargin} xs={12}>
                      <Field
                        component={InputField}
                        onChange={handleChange}
                        name="secondaryEmail"
                        value={values.secondaryEmail}
                        fullWidth
                        label="Secondary Email"
                      />
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.bottomMargin}>
                      <Field
                        component={InputField}
                        onChange={handleChange}
                        name="primaryPhone"
                        value={values.primaryPhone}
                        fullWidth
                        label="Primary Phone"
                        InputProps={{
                          inputProps: {
                            maxlength: "20",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.bottomMargin}>
                      <Field
                        component={InputField}
                        onChange={handleChange}
                        name="secondaryPhone"
                        value={values.secondaryPhone}
                        fullWidth
                        label="Secondary Phone"
                        InputProps={{
                          inputProps: {
                            maxlength: "20",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Field
                        component={InputField}
                        onChange={handleChange}
                        name="notes"
                        placeholder="Industry, Gross Revenue/Income, Entity Structure, # of Employees, Accounting/Payroll software, Legacy goals for the business, etc."
                        multiline
                        minRows={3}
                        value={values.notes}
                        fullWidth
                        label="Notes"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    margin: "1rem 0",
                  }}
                >
                  <Divider light />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container justifyContent="space-between" spacing={4}>
                    <Grid item xs={12}>
                      <ListItem>
                        <StyledListItemText
                          primary="Client Discovery"
                          secondary="Use these questions as the basis for your Discovery Meeting with the client at the end"
                          classes={{
                            secondary: classes.secondaryBlueText,
                          }}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.qLabel}>
                        1. No matter what happens over the next 12 months, what progress do you want to accomplish in the next year?
                      </Typography>
                      <Field
                        component={InputField}
                        onChange={handleChange}
                        name="input1"
                        value={values.input1}
                        fullWidth
                        classes={classes.spaceBetween}
                        label=""
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.qLabel}>
                        2. How will accomplishing this make your life and/or business better? What will the tangible impact be?
                      </Typography>
                      <Field component={InputField} onChange={handleChange} name="input2" value={values.input2} fullWidth label="" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.qLabel}>
                        3. Other clients who also wrestled with [give examples]. Has that been an issue for you? Or would you like to solve this as well?
                      </Typography>
                      <Field component={InputField} onChange={handleChange} name="input3" value={values.input3} fullWidth label="" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.qLabel}>
                        4. All of these items are important and interrelated, what are your top three priorities:
                      </Typography>
                      <Grid container spacing={2} alignItems="center" justifyContent="space-around">
                        <Grid item xs={2} container alignItems="center" className={classes.subPoint}>
                          <CustomTypography className={classes.prioritiesLabel}>1st Priority?</CustomTypography>
                        </Grid>
                        <Grid item xs={10} className={classes.subField}>
                          <Field component={InputField} onChange={handleChange} name="firstPriority" value={values.firstPriority} fullWidth />
                        </Grid>
                        <Grid item xs={2} className={classes.subPoint}>
                          <Typography className={classes.prioritiesLabel}>2nd Priority?</Typography>
                        </Grid>
                        <Grid item xs={10} className={classes.subField}>
                          <Field component={InputField} onChange={handleChange} name="secondPriority" value={values.secondPriority} fullWidth />
                        </Grid>
                        <Grid item xs={2} className={classes.subPoint}>
                          <Typography className={classes.prioritiesLabel}>3rd Priority?</Typography>
                        </Grid>
                        <Grid item xs={10} className={classes.subField}>
                          <Field component={InputField} onChange={handleChange} name="thirdPriority" value={values.thirdPriority} fullWidth />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.qLabel}>5. Is there anythine else we haven't addressed that's on your mind?</Typography>
                      <Field component={InputField} onChange={handleChange} name="input5" value={values.input5} fullWidth label="" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.qLabel} />
                      <Typography className={classes.qLabel}>6. Do you feel the client is aligned? (Product, Price, Personality and Skillset)</Typography>
                      <RadioGroup
                        className={classes.radioGroup}
                        row
                        name="isClientAligned"
                        value={this.props.isClientAligned}
                        onChange={(e) =>
                          this.props.setState({
                            [e.target.name]: e.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          className={classes.yesRadio}
                          value="Yes"
                          control={
                            <Radio
                              color="primary"
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                              style={{
                                color: theme.palette.primary.main,
                              }}
                            />
                          }
                          label="Yes"
                          classes={{
                            label: classes.radioLabel,
                          }}
                        />
                        <FormControlLabel
                          className={classes.noRadio}
                          value="No"
                          control={
                            <Radio
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                              style={{
                                color: theme.palette.error.main,
                              }}
                            />
                          }
                          label="No"
                          classes={{
                            label: classes.radioLabel,
                          }}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.qLabel}>7. Thank you for meeting today, here are the next steps:</Typography>
                      <List dense={true}>
                        <ListItem style={{ position: "relative" }}>
                          <ListItemText
                            primary="We'll put together a roadmap document that details how we can best help with the results we discussed today including pricing."
                            classes={{
                              primary: classes.listItemPrimary,
                            }}
                          />
                        </ListItem>
                        <ListItem style={{ position: "relative" }}>
                          <ListItemText
                            classes={{
                              primary: classes.listItemPrimary,
                            }}
                            primary="We'll schedule one more quick call together to review the roadmap, answer any questions, and modify the roadmap if necessary."
                          />
                        </ListItem>
                        <ListItem style={{ position: "relative" }}>
                          <ListItemText
                            classes={{
                              primary: classes.listItemPrimary,
                            }}
                            primary="Once everything makes sense and is agreed upon, we can allocate resources for your work and get started."
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.qLabel} display="inline">
                        8.
                      </Typography>
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            checked={this.props.scheduleDateTime}
                            onChange={(e) => {
                              this.props.setState({
                                scheduleDateTime: e.target.checked,
                              });
                            }}
                            name="scheduleDateTime"
                            style={{
                              color: theme.palette.primary.main,
                            }}
                          />
                        }
                        label="Schedule date & time for Roadmap Review meeting"
                        classes={{
                          label: classes.checkboxLabel,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} className={classes.cardPaper}>
                  <Grid container direction="row" justifyContent="flex-end" spacing={2}>
                    <Grid item xs={6} md={2} container justifyContent="flex-end">
                      <BootstrapTooltip title={<QuizDialog classes={classes} />} arrow placement="top-start">
                        <Button
                          color="primary"
                          onClick={() => {
                            this.props.openTakeQuiz(values);
                          }}
                          variant="outlined"
                        >
                          {this.props.getQuizText()}
                        </Button>
                      </BootstrapTooltip>
                    </Grid>
                    <Grid item xs={6} md={2} container justifyContent="flex-end">
                      <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
                        Save & continue
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const ClientInfoWithRouter = withRouter(ClientInfo);
const ClientInfoAlertBox = withAlertBox(ClientInfoWithRouter);
const ClientInfoLoader = withLoader(ClientInfoAlertBox);
const ClientInfoToast = withToast(ClientInfoLoader);
const ClientInfoWithDialog = withDialog(ClientInfoToast);

export default withStyles(
  (theme) =>
    createStyles({
      yesRadio: {
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        "& .MuiIconButton-label": {
          border: "1px",
          color: "#89c0c1",
        },
      },
      noRadio: {
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        "& .MuiIconButton-label": {
          color: "#ffa3a3",
        },
      },
      cardPaper: {
        [theme.breakpoints.up("md")]: {
          margin: theme.spacing(2, 1),
        },
        margin: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      spaceBetween: {
        marginTop: "50px",
        "& .InputFieldWithFormik-inputfieledroot-39": {
          marginTop: "50px",
        },
      },
      qLabel: {
        fontSize: "0.95rem",
        marginBottom: "0.625rem",
        color: theme.palette.text.secondary,
      },
      checkboxLabel: {
        fontSize: "0.95rem",
        color: theme.palette.text.secondary,
      },
      prioritiesLabel: {
        fontSize: "1.1rem",
        fontWeight: 700,
        marginBottom: "0.625rem",
        color: "#3a3f63",
      },
      subPoint: {
        width: "15%",
      },
      subField: {
        width: "85%",
      },
      dialogBox: {
        width: "250px",
      },
      tooltipHeading: {
        color: theme.palette.text.blue2,
        fontSize: "1rem",
      },
      bulbIcon: {
        width: "45px",
        height: "50px",
      },
      listItemPrimary: {
        fontSize: "0.95rem",
        fontFamily: "Lato",
        color: theme.palette.text.secondary,
        paddingBottom: "0.625rem",
        paddingLeft: "2rem",
        "&:before": {
          content: '""',
          position: "absolute",
          left: 0,
          top: "25%",
          width: "10px",
          height: "10px",
          display: "block",
          backgroundColor: "#3a3f63",
          borderRadius: "50%",
        },
      },
      secondaryBlueText: {
        color: theme.palette.text.blue2,
        fontWeight: "normal",
      },
      bottomMargin: {
        marginBottom: "1rem",
      },
      radioLabel: {
        color: theme.palette.text.blue2,
        fontWeight: "bold",
      },
    }),
  { withTheme: true }
)(withConfirmBox(ClientInfoWithDialog));
