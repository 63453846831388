// @ts-nocheck
import React from "react";
import AddNewTemplateController, { Props } from "./AddNewTemplateController.web";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router-dom";
import withDialog from "../../../../components/src/withDialog.web";
import { Typography, Button, Grid, withStyles, createStyles, MenuItem } from "@material-ui/core";
import CustomGrid from "../../../../components/src/CustomGrid.web";
import CustomTypography from "../../../../components/src/CustomTypography.web";
import WhiteField from "../../../../components/src/WhiteField.web";
import WhiteFieldWithoutFormik from "../../../../components/src/WhiteFieldWithoutFormik.web";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import UploadBoxWhite from "../UploadBoxWhite.web";
import DeleteDialog from "../ClientList/DeleteDialog.web";
import { spaceMask } from "../masks";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required."),
});

export class AddNewTemplate extends AddNewTemplateController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    const accountId = await StorageProvider.get("accountId");
    this.setState(
      {
        token: token,
        accountId: accountId,
      },
      () => {
        this.getPackages();
      }
    );
  }

  openUnassignSinglePackageDialog = (documentPackage) => {
    this.props
      .openDialogBox({
        dataToPass: {
          dialogHeading: "Unassign Package",
          description: "Are you sure you want to unassign this package?",
        },
        width: "35%",
        renderedComponent: DeleteDialog,
        withCustomDialog: false,
      })
      .then(async (data: any) => {
        if (data === "Yes") {
          this.unassignSinglePackage(documentPackage);
        }
      });
  };

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          base64: reader.result,
          file: file,
          id: new Date().getTime(),
        });
      reader.onerror = (error) => reject(error);
    });
  };

  removeSelectedDocument = () => {
    this.setState({
      selectedDocument: null,
    });
  };

  handleSelectedDocument = async (files: FileList) => {
    const filePathsPromises = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      selectedDocument: filePaths[0],
    });
  };

  onSubmit = (values) => {
    if (this.state.documentTemplate?.id) {
      this.updateDocumentTemplate(values);
    } else {
      this.createDocumentTemplate(values);
    }
  };

  assignToAllClickHandler = (values) => {
    if (values.title === "" && this.state.selectedDocument === "") {
      this.props.showToast({
        type: "error",
        message: "Please provide title and document.",
      });
    } else if (values.title === "") {
      this.props.showToast({
        type: "error",
        message: "Please provide title",
      });
    } else if (this.state.selectedDocument === "") {
      this.props.showToast({
        type: "error",
        message: "Please provide document.",
      });
    } else {
      this.assignAllPackages(values);
    }
  };

  unassignToAllClickHandler = (values) => {
    if (this.state.documentTemplate?.id) {
      this.unassignAllPackages(values);
    } else {
      this.props.showToast({
        type: "error",
        message: "Please save template first.",
      });
    }
  };

  handleCancel = (setFieldValue) => {
    setFieldValue("title", "");
    this.setState({
      selectedDocument: "",
      assignToPackage: "Packages",
    });
  };

  render(): React.ReactNode {
    const { classes } = this.props;

    const uploadMsg = this.state.selectedDocument?.file?.name ? this.state.selectedDocument?.file?.name : "Add Document";

    let packageListTemp = [...this.state.packageList, ...this.state.customPackagesListDocumentPage];
    if (this.state.documentTemplate?.attributes?.document_template_packages?.data.length > 0) {
      packageListTemp = packageListTemp.filter((packageItem) => {
        let index = this.state.documentTemplate?.attributes?.document_template_packages?.data.findIndex(
          (doc) => +packageItem.id === +doc.attributes.package_management_id || +packageItem.id === +doc.attributes.custom_package_management_id
        );
        return index == -1;
      });
    }

    return (
      <Formik
        initialValues={{
          title: "",
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={this.onSubmit}
      >
        {(formikProps) => {
          const { values, handleChange, setFieldValue, isValid, dirty } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid container justifyContent="center">
                <CustomGrid item xs={12}>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<ChevronLeftIcon />}
                    className={classes.backButton}
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                  >
                    Back
                  </Button>
                  <Typography id="addNewTemplate-heading" className={classes.pageHeading} color="secondary">
                    Add New Template
                  </Typography>
                </CustomGrid>
                <CustomGrid item xs={12}>
                  <Grid container className={[classes.gridSpacing, classes.whiteCard]}>
                    <CustomGrid item xs={12} container className={classes.gridSpacing}>
                      <CustomGrid item xs={5} container justifyContent="space-between">
                        <CustomGrid item xs={5} container alignItems="center">
                          <Typography color="textPrimary" className={classes.fieldLabel}>
                            Title:
                          </Typography>
                        </CustomGrid>
                        <CustomGrid item xs={7} container>
                          <Field component={WhiteField} name="title" onChange={(e) => handleChange(spaceMask(e))} fullWidth placeholder="Enter Title" />
                        </CustomGrid>
                      </CustomGrid>
                    </CustomGrid>
                    <CustomGrid item xs={12} container className={classes.gridSpacing}>
                      <CustomGrid item xs={5} container justifyContent="space-between">
                        <CustomGrid item xs={5} container alignItems="center">
                          <Typography color="textPrimary" className={classes.fieldLabel}>
                            Upload Template:
                          </Typography>
                        </CustomGrid>
                        <CustomGrid item xs={7} container>
                          <UploadBoxWhite
                            multiple={false}
                            acceptedFormat={".doc, .docx"}
                            uploadMsg={uploadMsg}
                            onTouched={() => {}}
                            onSelectFile={this.handleSelectedDocument}
                            showToast={this.props.showToast}
                          />
                        </CustomGrid>
                      </CustomGrid>
                    </CustomGrid>
                    <CustomGrid item xs={12} container className={classes.gridSpacing}>
                      <CustomGrid item xs={5} container justifyContent="space-between">
                        <CustomGrid item xs={5} container alignItems="center">
                          <Typography color="textPrimary" className={classes.fieldLabel}>
                            Assign to Package:
                          </Typography>
                        </CustomGrid>
                        <CustomGrid item xs={7} container>
                          <WhiteFieldWithoutFormik
                            name="assignToPackage"
                            value={this.state.assignToPackage}
                            onChange={(e) => {
                              this.setState({
                                assignToPackage: e.target.value,
                              });
                            }}
                            fullWidth
                            select
                          >
                            <MenuItem value="Packages" disabled>
                              Packages
                            </MenuItem>
                            {packageListTemp.map((packageItem) => (
                              <MenuItem key={packageItem.id} value={packageItem.attributes.id}>
                                {packageItem.attributes.name}
                              </MenuItem>
                            ))}
                          </WhiteFieldWithoutFormik>
                        </CustomGrid>
                      </CustomGrid>
                      <CustomGrid item xs={5} container alignItems="center">
                        <CustomTypography
                          fontWeight={600}
                          fontSize="1rem"
                          color="secondary"
                          padding="0 0 0 1rem"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            pointerEvents: this.state.documentTemplate?.id ? "all" : "none",
                          }}
                          onClick={this.assignToAllClickHandler.bind(this, values)}
                        >
                          Assign to All{" "}
                        </CustomTypography>
                        <CustomTypography
                          fontWeight={600}
                          fontSize="1rem"
                          color="secondary"
                          style={{
                            padding: "0 0.25rem",
                          }}
                        >
                          /
                        </CustomTypography>
                        <CustomTypography
                          fontWeight={600}
                          fontSize="1rem"
                          color="secondary"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={this.unassignToAllClickHandler.bind(this, values)}
                        >
                          Unassign All
                        </CustomTypography>
                      </CustomGrid>
                    </CustomGrid>

                    <CustomGrid item xs={12} width="96%">
                      <CustomGrid container item xs={12} alignItems="flex-start" className={[classes.gridSpacing, classes.innerWhiteCard]}>
                        {this.state.documentTemplate && this.state.documentTemplate?.attributes?.document_template_packages?.data.length > 0 ? (
                          this.state.documentTemplate?.attributes?.document_template_packages?.data.map((documentPackage) => {
                            return (
                              <CustomGrid
                                key={documentPackage.id}
                                item
                                container
                                justifyContent="space-between"
                                width="31%"
                                margin="0 1% 0 0"
                                padding="1rem"
                                alignItems="center"
                              >
                                <Typography color="textSecondary">{documentPackage.attributes.package_management_name}</Typography>
                                <RemoveCircleIcon onClick={() => this.openUnassignSinglePackageDialog(documentPackage)} className={classes.crossButton} />
                              </CustomGrid>
                            );
                          })
                        ) : (
                          <CustomGrid item xs={3} container justifyContent="space-between" width="31%" margin="0 1% 0 0" padding="1rem" alignItems="center">
                            <Typography color="textSecondary">No packages assigned</Typography>
                          </CustomGrid>
                        )}
                      </CustomGrid>
                    </CustomGrid>

                    <CustomGrid item xs={3} className={classes.gridSpacing}>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={!this.state.documentTemplate?.id}
                        fullWidth
                        onClick={this.createTemplate.bind(this, this.state.documentTemplate)}
                      >
                        Customize Signature Fields
                      </Button>
                    </CustomGrid>
                    <CustomGrid item width="96%" container justifyContent="flex-end" className={classes.gridSpacing}>
                      <CustomGrid item width="14%" container justifyContent="flex-end" className={classes.gridSpacing}>
                        <Button
                          color="secondary"
                          variant="contained"
                          fullWidth
                          disabled={this.state.documentTemplate?.id}
                          onClick={this.handleCancel.bind(this, setFieldValue)}
                        >
                          Cancel
                        </Button>
                      </CustomGrid>{" "}
                      <CustomGrid item width="14%" margin="1rem 0 1rem 1rem !important" container justifyContent="flex-end" className={classes.gridSpacing}>
                        <Button
                          disabled={!(isValid && dirty) || this.state.selectedDocument === "" || this.state.assignToPackage === "Packages"}
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                        >
                          Save
                        </Button>
                      </CustomGrid>
                    </CustomGrid>
                  </Grid>
                </CustomGrid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const AddNewTemplateWithRouter = withRouter(AddNewTemplate);
const AddNewTemplateAlertBox = withAlertBox(AddNewTemplateWithRouter);
const AddNewTemplateLoader = withLoader(AddNewTemplateAlertBox);
const AddNewTemplateToast = withToast(AddNewTemplateLoader);

export default withStyles((theme) =>
  createStyles({
    center: {
      textAlign: "center",
    },
    marginTop: {
      marginTop: "3rem",
    },
    gridSpacing: {
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down("md")]: {
        margin: theme.spacing(1, 0),
      },
    },
    innerWhiteCard: {
      padding: theme.spacing(4),
      borderRadius: theme.spacing(2),
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.29)",
      minHeight: "15rem",
    },
    whiteCard: {
      padding: theme.spacing(4),
      borderRadius: theme.spacing(2),
      backgroundColor: "#ffffff",
      boxShadow: `0px 4px 18px rgba(0,0,0,0.1)`,
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1.5),
      },
    },
    heading: {
      fontWeight: 600,
      fontSize: "1.2rem",
    },
    pageHeading: {
      fontWeight: 600,
      fontSize: "1.5rem",
      paddingLeft: "1rem",
    },
    crossButton: {
      cursor: "pointer",
      color: theme.palette.error.main,
    },
    backButton: {
      padding: 0,
      paddingLeft: "1rem",
      fontSize: "1.1rem",
      margin: "1rem 0",
      height: "auto",
      "&& svg": {
        fontSize: "1.5rem",
      },
    },
    fieldLabel: {
      fontSize: "1.3rem",
      fontWeight: "bold",
    },
  })
)(withDialog(AddNewTemplateToast));
