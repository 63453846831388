//@ts-nocheck
import React from "react";
import { withStyles, Grid, createStyles, Button } from "@material-ui/core";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import DocViewer from "@cyntler/react-doc-viewer";

const MyCustomPNGRenderer: any = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;

  return (
    <div style={{ width: "100%", height: 500 }} id="my-png-renderer">
      <iframe
        style={{ width: "100%", height: 500 }}
        src={`https://docs.google.com/viewer?url=${currentDocument.uri}&embedded=true`}
      />
    </div>
  );
};
MyCustomPNGRenderer.fileTypes = ["doc", "docx"];
MyCustomPNGRenderer.weight = 1;

export class ViewDocumentDialog extends React.Component {
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({
        token: token,
      });
    }
  }

  open = () => {
    window.open(
      this.props.dataToPass?.documentItem?.attributes?.document,
      "_blank",
      "noopener"
    );
  };

  render() {
    const { classes } = this.props;
    const docs = [
      {
        uri: this.props.dataToPass?.documentItem?.attributes?.document,
        fileType: "doc",
      },
    ];

    return (
      <Grid container justifyContent="center" className={classes.rootContainer}>
        <Grid item xs={12}>
          <DocViewer
            documents={docs}
            prefetchMethod="GET"
            config={{
              header: {
                disableHeader: true,
              },
            }}
            pluginRenderers={[MyCustomPNGRenderer]}
            style={{ width: "100%", height: 500 }}
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={this.open.bind(this)}
          style={{
            width: "25%",
            marginTop: "1.5rem",
          }}
        >
          Download
        </Button>
      </Grid>
    );
  }
}

const ViewDocumentDialogWithRouter = withRouter(ViewDocumentDialog);
const ViewDocumentDialogWithToast = withToast(ViewDocumentDialogWithRouter);
const ViewDocumentDialogWithLoader = withLoader(ViewDocumentDialogWithToast);
const ViewDocumentDialogWithAlertBox = withAlertBox(
  ViewDocumentDialogWithLoader
);
const ViewDocumentDialogWithDialogBox = withDialogBox(
  ViewDocumentDialogWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      width: "60vw",
      paddingBottom: "2rem",
      marginTop: "2.5rem",
    },
  })
)(ViewDocumentDialogWithDialogBox);
